@charset "UTF-8";
:root {
	--meno-primary: #146ddd;
	--meno-hover-primary: #0b5dc2;

	--meno-secondary: #f7fafc;
	--meno-hover-secondary: #ebedf2;

	--meno-danger: #de3a2a;
	--meno-hover-danger: #c22e1f;

	--meno-success: #2dce89;
	--meno-hover-success: #24b77f;

	--meno-warning: #ff9f43;
	--meno-hover-warning: #ff8f1a;

	--meno-info: #11cdef;
	--meno-hover-info: #0da5c0;

	--meno-light: #f8f9fb;
	--meno-hover-light: #ebedf2;

	--meno-dark: #212529;
	--meno-hover-dark: #16181b;

	--meno-bg-active-primary: #eff6fe;
	--meno-bg-hover-primary: #eff6fe;
	--meno-bg-primary: #f2f3f5;

	--color-button-border-secondary: #cfcbcb;
	--color-button-border-secondary-hover: #afabac;
	--color-button-border-secondary-active: #6d6e6f;

	--color-button-background-secondary-hover: #f9f8f8;

	--color-text: #1e1f21;
	--color-text-secondary: #8d99a6;
	--color-border: #dddddd;
	--color-border-focus: #1180f6;
	--color-border-hover: #afabac;
	--color-border-active: #6d6e6f;
	--color-border-strong: #cfcbcb;
}

/* Menu */

.bg-menu-theme {
	background-color: #fff !important;
	color: #677788;
}
.bg-menu-theme .menu-link,
.bg-menu-theme .menu-horizontal-prev,
.bg-menu-theme .menu-horizontal-next {
	color: #677788;
}
.bg-menu-theme .menu-link.active,
.bg-menu-theme .menu-horizontal-prev.active,
.bg-menu-theme .menu-horizontal-next.active {
	color: var(--meno-primary);
}
.bg-menu-theme .menu-item.disabled .menu-link,
.bg-menu-theme .menu-horizontal-prev.disabled,
.bg-menu-theme .menu-horizontal-next.disabled {
	color: #a4adb8 !important;
}
.bg-menu-theme .menu-item.active > .menu-link:not(.menu-toggle) {
	color: var(--meno-primary);
	background-color: var(--meno-bg-active-primary);
}
.bg-menu-theme.menu-horizontal .menu-inner > .menu-item.active > .menu-link {
	background-color: var(--meno-bg-active-primary) !important;
}
.bg-menu-theme.menu-horizontal .menu-sub > .menu-item.active > .menu-link:not(.menu-toggle) {
	background-color: transparent !important;
	color: var(--meno-primary) !important;
}
.bg-menu-theme .menu-inner > .menu-item.menu-item-closing .menu-item.open .menu-sub,
.bg-menu-theme .menu-inner > .menu-item.menu-item-closing .menu-item.open .menu-toggle {
	background: transparent;
	color: #677788;
}
.bg-menu-theme .menu-inner-shadow {
	background: linear-gradient(#fff 41%, rgba(255, 255, 255, 0.11) 95%, rgba(255, 255, 255, 0));
}
.bg-menu-theme .menu-text {
	color: var(--meno-primary);
}
.bg-menu-theme .menu-header {
	color: #8d99a6;
}
.bg-menu-theme hr,
.bg-menu-theme .menu-divider,
.bg-menu-theme .menu-inner > .menu-item.open > .menu-sub::before {
	border-color: transparent !important;
}
.bg-menu-theme .menu-inner > .menu-header::before {
	background-color: transparent;
}
.bg-menu-theme .menu-block::before {
	background-color: #8d99a6;
}
.bg-menu-theme .menu-inner > .menu-item.open .menu-item.open > .menu-toggle::before {
	background-color: var(--meno-bg-active-primary);
}
.bg-menu-theme .menu-inner > .menu-item.open .menu-item.active > .menu-link::before {
	background-color: var(--meno-primary);
}
.bg-menu-theme .ps__thumb-y,
.bg-menu-theme .ps__rail-y.ps--clicking > .ps__thumb-y {
	background: rgba(90, 141, 238, 0.2) !important;
}

.layout-menu {
	box-shadow: 0 0 0 1px #d4d8dd;
}

.layout-menu-horizontal {
	box-shadow: 0 -1px 0 #d4d8dd inset;
}

.bg-menu-theme .menu-header {
	color: #a8b1bb;
}
.bg-menu-theme .menu-inner .menu-item.open > .menu-link:hover,
.bg-menu-theme .menu-inner .menu-item:not(.active) .menu-link:hover {
	background-color: var(--meno-bg-hover-primary);
}
.bg-menu-theme .menu-inner .menu-sub > .menu-item.active > .menu-link.menu-toggle,
.bg-menu-theme .menu-inner .menu-sub > .menu-item.open > .menu-link.menu-toggle {
	background-color: var(--meno-bg-hover-primary);
}
.bg-menu-theme .menu-inner .menu-sub > .menu-item.active > .menu-link.menu-toggle .menu-icon,
.bg-menu-theme .menu-inner .menu-sub > .menu-item.open > .menu-link.menu-toggle .menu-icon {
	color: var(--meno-primary);
}
html:not(.layout-menu-collapsed) .bg-menu-theme .menu-inner > .menu-item.open:not(.active),
.layout-menu-hover.layout-menu-collapsed .bg-menu-theme .menu-inner > .menu-item.open:not(.active) {
	background-color: var(--meno-bg-primary);
}
.bg-menu-theme .menu-inner > .menu-item.active {
	background-color: var(--meno-bg-primary);
}
.layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-expanded)
	.bg-menu-theme
	.menu-inner
	> .menu-item.active {
	background-color: var(--meno-bg-active-primary);
}
.layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-expanded)
	.bg-menu-theme
	.menu-inner
	> .menu-item.active
	.menu-link {
	color: var(--meno-primary);
}
.bg-menu-theme .menu-sub > .menu-item > .menu-link:before {
	background-color: #a8b1bb !important;
}
.bg-menu-theme .menu-sub > .menu-item.active > .menu-link:not(.menu-toggle):before {
	background-color: var(--meno-primary) !important;
}
.bg-menu-theme .menu-inner > .menu-header::before {
	background-color: #7d8a99;
}
.bg-menu-theme.menu-horizontal {
	background-color: #fff !important;
	box-shadow: none;
	border-bottom: 1px solid #d4d8dd;
}
.bg-menu-theme.menu-horizontal .menu-inner > .menu-item.active > .menu-link {
	background-color: var(--meno-bg-active-primary) !important;
}
.bg-menu-theme.menu-horizontal .menu-inner > .menu-item .menu-sub {
	box-shadow: none;
	border: 1px solid #d4d8dd;
}
html:not(.layout-menu-collapsed) .bg-menu-theme.menu-horizontal .menu-inner .menu-item.open:not(.active) > .menu-link,
.layout-menu-hover.layout-menu-collapsed
	.bg-menu-theme.menu-horizontal
	.menu-inner
	.menu-item.open:not(.active)
	> .menu-link,
html:not(.layout-menu-collapsed) .bg-menu-theme.menu-horizontal .menu-inner .menu-item:not(.active) .menu-link:hover,
.layout-menu-hover.layout-menu-collapsed
	.bg-menu-theme.menu-horizontal
	.menu-inner
	.menu-item:not(.active)
	.menu-link:hover,
html:not(.layout-menu-collapsed)
	.bg-menu-theme.menu-horizontal
	.menu-inner
	.menu-sub
	.menu-item.active
	.menu-link:not(.menu-toggle):hover,
.layout-menu-hover.layout-menu-collapsed
	.bg-menu-theme.menu-horizontal
	.menu-inner
	.menu-sub
	.menu-item.active
	.menu-link:not(.menu-toggle):hover {
	background-color: var(--meno-bg-hover-primary) !important;
	color: #516377;
}
html:not(.layout-menu-collapsed) .bg-menu-theme.menu-horizontal .menu-inner > .menu-item.open:not(.active) {
	background-color: transparent;
}
.bg-menu-theme.menu-horizontal .menu-inner .menu-item:not(.menu-item-closing) > .menu-sub {
	background: #fff !important;
}
.bg-menu-theme.menu-horizontal .menu-inner .menu-sub > .menu-item.active > .menu-link.menu-toggle,
.bg-menu-theme.menu-horizontal .menu-inner .menu-sub > .menu-item.open > .menu-link.menu-toggle {
	background-color: var(--meno-bg-hover-primary) !important;
}
.bg-menu-theme.menu-horizontal .menu-inner .menu-sub > .menu-item.open:not(.active) .menu-link.menu-toggle .menu-icon {
	color: #677788;
}
.bg-menu-theme.menu-horizontal .menu-inner > .menu-item.active > .menu-link {
	color: var(--meno-primary);
}

.timeline .timeline-item .timeline-event:after {
	content: '';
}

.app-brand .layout-menu-toggle i {
	color: var(--meno-primary);
}

.bg-menu-theme .menu-link,
.bg-menu-theme .menu-horizontal-prev,
.bg-menu-theme .menu-horizontal-next {
	font-weight: 500;
}

.menu-item.open > .menu-sub {
	background: #fff;
}

/* Table */
.table {
	border-color: #ebecf0;
}

.table th {
	text-transform: none;
	font-size: 15px;
	letter-spacing: 0px;
	font-weight: 500;
}

.table > :not(:first-child) {
	border-top: 0px solid #d4d8dd;
}

html:not([dir='rtl']) div.card-datatable table.dataTable thead th:first-child,
html:not([dir='rtl']) div.card-datatable table.dataTable tfoot th:first-child {
	padding-block: 14px;
}

/* btn-tab */
.btn-tab {
	background-color: var(--meno-bg-primary);
	color: #303135;
	font-weight: 600;
}
.btn-tab:hover {
	background-color: var(--meno-bg-hover-primary);
	color: var(--meno-primary);
	font-weight: 600;
}
.btn-tab:focus,
.btn-tab.focus {
	background-color: var(--meno-bg-active-primary);
	color: #146ddd;
	font-weight: 600;
}
.btn-tab:active,
.btn-tab.active {
	background-color: var(--meno-bg-active-primary);
	color: #146ddd;
	font-weight: 600;
}
.btn-tab:active:focus,
.btn-tab.active:focus {
	box-shadow: none;
}

.btn-tab:hover i {
	color: var(--meno-primary);
}

.btn-tab:active i,
.btn-tab.active i {
	color: var(--meno-primary);
}

.btn-tab i {
	color: #818286;
}

/* Card */

.card {
	box-shadow: none;
	/*border: 1px solid #dadce0; /* google */
	border: 1px solid var(--color-border); /* asana */
}

/* Navbar */

.bg-navbar-theme {
	border-bottom: 2px solid #f0f2f5;
}

/* Content */
.content-wrapper .container-p-y:not([class^='pt-']):not([class*=' pt-']) {
	padding-top: 2rem !important;
}

/* Heading */

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
	margin-top: 0;
	margin-bottom: 1rem;
	font-family: 'Roboto', 'Segoe UI', 'Rubik', 'Times New Roman', serif;
	font-weight: 500;
	line-height: 1.1;
	color: var(--color-text);
}

/* Modal */

.modal-header {
	display: flex;
	flex-shrink: 0;
	align-items: center;
	justify-content: space-between;
	padding: 1.375rem 1.5rem;
	border-bottom: 1px solid var(--color-border);
	border-top-left-radius: calc(0.3125rem - 0px);
	border-top-right-radius: calc(0.3125rem - 0px);
}

.modal-content {
	box-shadow: none;
}

.modal-footer > * {
	margin: 0;
}

/* Button */

/* .btn + .btn {
	margin: 0 0 0 12px;
}

a.btn + a.btn {
	margin: 0 0 0 12px;
} */

.btn-primary,
.btn-primary:focus,
.btn-primary.focus {
	color: #fff;
	background-color: var(--meno-primary);
	border-color: var(--meno-primary);
	box-shadow: none;
}
.btn-primary:hover {
	color: #fff;
	background-color: var(--meno-hover-primary);
	border-color: var(--meno-hover-primary);
	box-shadow: none;
}

.btn-danger,
.btn-danger:focus,
.btn-danger.focus {
	color: #fff;
	background-color: var(--meno-danger);
	border-color: var(--meno-danger);
	box-shadow: none;
}
.btn-danger:hover {
	color: #fff;
	background-color: var(--meno-hover-danger);
	border-color: var(--meno-hover-danger);
	box-shadow: none;
}

.btn-outline-secondary,
.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
	color: var(--color-text);
	border-color: var(--color-button-border-secondary);
	background: transparent;
}
.btn-outline-secondary:hover {
	color: var(--color-text);
	background-color: var(--color-button-background-secondary-hover);
	border-color: var(--color-button-border-secondary-hover);
}

/* Form */

.form-label,
.col-form-label {
	font-size: 0.75rem;
	text-transform: none;
	letter-spacing: inherit;
}

.form-select {
	border: 1px solid var(--color-border);
	color: var(--color-text);
}

.form-select:hover {
	border: 1px solid var(--color-border-hover);
	color: var(--color-text);
}

.form-select:focus {
	border: 2px solid var(--color-border-focus);
	color: var(--color-text);
}

.form-control {
	border: 1px solid var(--color-border);
	color: var(--color-text);
}

.form-control:hover {
	border: 1px solid var(--color-border-hover);
	color: var(--color-text);
}

.form-control:focus {
	color: var(--color-text);
	background-color: #fff;
	border: 2px solid var(--color-border-focus);
	outline: 0;
	box-shadow: none;
}

/* a */
a {
	color: var(--meno-primary);
}

/* Toast */

.Toastify__toast {
	background: #fff;
	color: var(--color-text);
}

.Toastify__toast {
	box-shadow: none;
	border: 1px solid var(--color-border);
	font-family: 'Roboto';
}

/* body */

body {
	color: var(--color-text);
}

/* typography */

b,
strong {
	font-weight: 500;
}

/* border */

.border {
	border-color: var(--color-border) !important;
}

html:not([dir='rtl']) .border-start {
	border-color: var(--color-border) !important;
}

html:not([dir='rtl']) .border-end {
	border-color: var(--color-border) !important;
}

html:not([dir='rtl']) .border-top {
	border-color: var(--color-border) !important;
}

html:not([dir='rtl']) .border-bottom {
	border-color: var(--color-border) !important;
}

/* Breadcrumb */

.breadcrumb-item + .breadcrumb-item::before {
	float: left;
	padding-right: 0.5rem;
	color: #939eaa;
	content: var(--bs-breadcrumb-divider, '/') /* rtl: var(--bs-breadcrumb-divider, "\\") */;
}

.breadcrumb-item,
.breadcrumb-item a {
	color: var(--color-text);
}

.breadcrumb-item.active {
	color: var(--color-text);
}

.breadcrumb-item:hover,
.breadcrumb-item:focus,
.breadcrumb-item a:hover,
.breadcrumb-item a:focus {
	font-weight: 400;
	color: var(--color-text);
}

/* IK */

.btn-icon.btn-sm:hover {
	color: #5788e6;
}

/* dropbox */

.dropbox {
	border: 1px solid var(--color-border);
	color: var(--color-text);
	display: block;
	width: 100%;
	min-height: calc(1.4em + 0.938rem + 2px);
	padding: 0.469rem 0.735rem;
	font-size: 0.9375rem;
	font-weight: 400;
	line-height: 1.4;
	background-color: #fff;
	background-clip: padding-box;
	appearance: none;
	border-radius: 0.25rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.dropbox:hover {
	border: 1px solid var(--color-border-hover);
	color: var(--color-text);
}

.dropbox:focus {
	border: 2px solid var(--color-border-focus);
	color: var(--color-text);
}

/* context-menu */

.szh-menu {
	-webkit-user-select: none !important;
	user-select: none;
	color: #212529;
	border: none;
	border-radius: 0.25rem;
	box-shadow: 0 3px 7px rgb(0 0 0 / 13%), 0 0.6px 2px rgb(0 0 0 / 10%) !important;
	min-width: 10rem;
	padding: 0.5rem 0 !important;
	background-color: #fff !important;
}

/* validation */

.invalid-feedback {
	display: block;
	width: 100%;
	margin-top: 0.25rem;
	font-size: 85%;
	color: var(--meno-danger);
}

/* flatpickr */

.flatpickr-input[readonly] {
	cursor: auto;
}

/* form-control */

.form-control:disabled {
	background: #f5f5f5 !important;
}

/* borders */

.border-primary {
	border-color: #5a8dee !important;
}

.border-secondary {
	border-color: #69809a !important;
}

.border-success {
	border-color: #39da8a !important;
}

.border-info {
	border-color: #00cfdd !important;
}

.border-warning {
	border-color: #fdac41 !important;
}

.border-danger {
	border-color: #ff5b5c !important;
}

.border-light {
	border-color: rgba(38, 60, 85, 0.1) !important;
}

.border-dark {
	border-color: #495563 !important;
}

.border-gray {
	border-color: rgba(38, 60, 85, 0.5) !important;
}

.border-white {
	border-color: #fff !important;
}

.border-transparent {
	border-color: transparent !important;
}

.form-check-input:checked {
	background-color: var(--meno-primary);
	border-color: var(--meno-primary);
}

.form-text {
	color: #828991;
}

.text-muted {
	color: #828991;
}

/* editable input */

.editable-input {
	border: 1px solid transparent;
	color: var(--color-text);
}

.editable-input:hover {
	border: 1px solid var(--color-border-hover);
	color: var(--color-text);
}

.editable-input:focus {
	border: 1px solid var(--color-border-focus);
	color: var(--color-text);
}

.editable-input:disabled {
	background: transparent !important;
}

.text-danger {
	color: var(--meno-danger) !important;
}

.text-success {
	color: var(--meno-success) !important;
}

.text-warning {
	color: var(--meno-warning) !important;
}

.text-info {
	color: var(--meno-info) !important;
}

.text-primary {
	color: var(--meno-primary) !important;
}

.bg-primary {
	background-color: var(--meno-primary) !important;
}

.bg-secondary {
	background-color: var(--meno-secondary) !important;
}

.bg-success {
	background-color: var(--meno-success) !important;
}

.bg-info {
	background-color: var(--meno-info) !important;
}

.bg-warning {
	background-color: var(--meno-warning) !important;
}

.bg-danger {
	background-color: var(--meno-danger) !important;
}

.bg-light {
	background-color: var(--meno-light) !important;
}

.bg-dark {
	background-color: var(--meno-dark) !important;
}

.dropdown-notifications-item:not(.mark-as-read) .dropdown-notifications-read span {
	background-color: var(--meno-primary);
}

/* theme */

.page-item.active .page-link,
.page-item.active .page-link:hover,
.page-item.active .page-link:focus,
.pagination li.active > a:not(.page-link),
.pagination li.active > a:not(.page-link):hover,
.pagination li.active > a:not(.page-link):focus {
	background-color: var(--meno-primary);
	border-color: var(--meno-primary);
}

.bg-menu-theme .menu-link,
.bg-menu-theme .menu-horizontal-prev,
.bg-menu-theme .menu-horizontal-next {
	color: #62666c;
}

/* auth */

.authentication-wrapper {
	display: flex;
	flex-basis: 100%;
	min-height: 100vh;
	width: 100%;
}
.authentication-wrapper .authentication-inner {
	width: 100%;
}
.authentication-wrapper.authentication-basic {
	align-items: center;
	justify-content: center;
	overflow: hidden;
}
.authentication-wrapper.authentication-cover {
	align-items: flex-start;
}
.authentication-wrapper.authentication-cover .authentication-inner {
	height: 100vh;
}
.authentication-wrapper.authentication-basic .authentication-inner {
	max-width: 25rem;
	position: relative;
}
.authentication-wrapper.authentication-basic .authentication-inner:before {
	width: 21rem;
	height: 21rem;
	content: ' ';
	position: fixed;
	top: -100px;
	background-size: contain;
}
html:not([dir='rtl']) .authentication-wrapper.authentication-basic .authentication-inner:before {
	left: -100px;
}
[dir='rtl'] .authentication-wrapper.authentication-basic .authentication-inner:before {
	right: -100px;
	transform: rotate(90deg);
}
.authentication-wrapper.authentication-basic .authentication-inner:after {
	width: 7rem;
	height: 14.125rem;
	content: ' ';
	position: fixed;
	bottom: 0;
	background-size: contain;
}
html:not([dir='rtl']) .authentication-wrapper.authentication-basic .authentication-inner:after {
	right: 0;
}
[dir='rtl'] .authentication-wrapper.authentication-basic .authentication-inner:after {
	left: 0;
	transform: rotate(180deg);
}
.authentication-wrapper.authentication-basic .authentication-inner .card {
	z-index: 1;
}
.authentication-wrapper.authentication-basic .authentication-inner .card .app-brand {
	margin-bottom: 2.5rem;
}
.authentication-wrapper .auth-input-wrapper .auth-input {
	max-width: 50px;
	padding-left: 0.4rem;
	padding-right: 0.4rem;
	font-size: 150%;
}
.authentication-cover-img {
	margin-bottom: 1rem;
}
@media (max-width: 575.98px) {
	.authentication-wrapper .auth-input-wrapper .auth-input {
		font-size: 1.125rem;
	}
}
#twoStepsForm .fv-plugins-bootstrap5-row-invalid .form-control {
	border-color: #ff5b5c;
}
.light-style .authentication-wrapper .authentication-bg {
	background-color: #fff;
}
.dark-style .authentication-wrapper .authentication-bg {
	background-color: #283144;
}
@media (min-height: 737px) {
	.authentication-cover-img {
		margin-bottom: 3rem;
	}
}

/* Flatpicker */

.flatpickr-day.selected,
.flatpickr-day.selected.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.startRange:focus,
.flatpickr-day.startRange:hover,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.endRange:focus,
.flatpickr-day.endRange:hover,
.flatpickr-day.endRange.nextMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.week.selected {
	color: #fff !important;
	background: var(--meno-primary) !important;
	border-color: var(--meno-primary) !important;
}

.flatpickr-day.today {
	border-color: var(--meno-primary) !important;
}

.light-style .flatpickr-day {
	color: var(--color-text);
}

.light-style .flatpickr-day.prevMonthDay,
.light-style .flatpickr-day.nextMonthDay,
.light-style .flatpickr-day.flatpickr-disabled {
	color: var(--color-text-secondary);
}

.light-style .flatpickr-monthDropdown-months {
	color: var(--color-text);
}

.flatpickr-prev-month,
.flatpickr-next-month {
	border: 1px solid var(--meno-primary);
}

.light-style .flatpickr-calendar {
	box-shadow: none;
	border: 1px solid var(--color-border);
}

/* .btn {
	display: flex;
	flex-direction: row;
	align-items: center;
}
 */

.switch-input:checked ~ .switch-toggle-slider {
	background-color: var(--meno-primary);
}

.switch-lg {
	width: 50px;
}

.switch {
	margin-right: 0;
}

.tooltip-container {
	background-color: var(--meno-light);
	color: var(--color-text);
	padding: 3px 6px;
	border-radius: 0.3125rem;
	box-shadow: none;
	font-size: 12px;
	z-index: 10;
}

.tabs-line.nav-tabs .nav-link.active,
.tabs-line.nav-tabs .nav-link.active:hover,
.tabs-line.nav-tabs .nav-link.active:focus,
.tabs-line > .nav-tabs .nav-link.active,
.tabs-line > .nav-tabs .nav-link.active:hover,
.tabs-line > .nav-tabs .nav-link.active:focus {
	box-shadow: 0 -2px 0 var(--meno-primary) inset;
}

.nav-link {
	color: var(--color-text);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
	color: var(--meno-primary);
}

.avatar img {
	object-fit: cover;
}

.project-card {
	background-color: #9a9fa1;
	transition: 0.3s ease-in-out;
}

.project-card:hover {
	background-color: #888b8d;
}

.light-style .flatpickr-calendar.open {
	z-index: 9999;
}

html {
	color-scheme: light;
	--lightness: 70%;
}

.dark {
	color-scheme: dark;
	--lightness: 45%;
	--main-bg: rgba(34, 29, 43, 0.7);
	--main-bg-2: #18141f;
	--main-bg-3: #0c0a0f;
}

@media (pointer: fine) {
	::-webkit-scrollbar {
		width: 1rem;
		height: 1.5rem;
	}

	::-webkit-scrollbar-thumb {
		background-color: hsla(0, 0%, var(--lightness), 0.7);
		border-radius: 9000000000px;
		border: 4px solid transparent;
		border-width: 6px 4px 5px 4px;
		background-clip: content-box;
	}

	::-webkit-scrollbar-thumb:hover {
		background-color: hsla(0, 0%, var(--lightness), 0.8);
	}
}
