@charset "UTF-8";
/*
* Pages Mixins
*/
.layout-navbar-fixed .layout-wrapper:not(.layout-horizontal) .layout-page:before {
  content: "";
  width: 100%;
  height: 0.75rem;
  position: fixed;
  top: 0px;
  z-index: 10;
}

.layout-wrapper:not(.layout-horizontal) .bg-menu-theme .menu-inner .menu-item,
.layout-wrapper:not(.layout-horizontal) .bg-menu-theme .menu-inner .menu-item.active .menu-link {
  border-radius: 0.3125rem;
}
.layout-horizontal .bg-menu-theme .menu-inner > .menu-item,
.layout-horizontal .bg-menu-theme .menu-inner > .menu-item > .menu-link {
  border-radius: 0.3125rem;
}
.bg-menu-theme .menu-inner .menu-item.active > .menu-link,
.bg-menu-theme .menu-inner .menu-item:not(.open) > .menu-link:hover,
.bg-menu-theme .menu-inner .menu-item:not(.active) > .menu-link:hover {
  border-radius: 0.3125rem;
}
@media (min-width: 1200px) {
  .layout-horizontal .bg-menu-theme .menu-inner > .menu-item {
    margin: 0.565rem 0;
  }
  .layout-horizontal .bg-menu-theme .menu-inner > .menu-item:not(:first-child) {
    margin-left: 0.0625rem;
  }
  .layout-horizontal .bg-menu-theme .menu-inner > .menu-item:not(:last-child) {
    margin-right: 0.0625rem;
  }
  .layout-horizontal .bg-menu-theme .menu-inner > .menu-item .menu-sub {
    box-shadow: 0 0.625rem 1.25rem rgba(147, 158, 170, 0.5);
  }
}
.bg-menu-theme .menu-sub > .menu-item > .menu-link:before {
  content: "";
  position: absolute;
  left: 1.4375rem;
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
}
[dir=rtl] .bg-menu-theme .menu-sub > .menu-item > .menu-link:before {
  right: 1.4375rem;
  left: inherit;
}
.layout-horizontal .bg-menu-theme .menu-sub > .menu-item > .menu-link:before {
  left: 1.3rem;
}
[dir=rtl] .layout-horizontal .bg-menu-theme .menu-sub > .menu-item > .menu-link:before {
  right: 1.3rem;
  left: inherit;
}
.bg-menu-theme .menu-horizontal-wrapper > .menu-inner > .menu-item > .menu-sub > .menu-item > .menu-link:before {
  display: none;
}

.layout-menu-hover .layout-menu {
  box-shadow: 0 0.625rem 1.25rem rgba(147, 158, 170, 0.5);
  transition: all 0.3s ease-in-out;
}

body {
  background: #fff;
}

.bg-body {
  background: #fff !important;
}

.text-primary {
  color: #5a8dee !important;
}

.text-body[href]:hover {
  color: #517fd6 !important;
}

.bg-primary {
  background-color: #5a8dee !important;
}

a.bg-primary:hover, a.bg-primary:focus {
  background-color: #5686e2 !important;
}

.dropdown-notifications-item:not(.mark-as-read) .dropdown-notifications-read span {
  background-color: #5a8dee;
}

.bg-label-primary {
  background-color: #e5edfc !important;
  color: #5a8dee !important;
}

.page-item.active .page-link, .page-item.active .page-link:hover, .page-item.active .page-link:focus,
.pagination li.active > a:not(.page-link),
.pagination li.active > a:not(.page-link):hover,
.pagination li.active > a:not(.page-link):focus {
  border-color: #5a8dee;
  background-color: #5a8dee;
  color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(147, 158, 170, 0.4);
}

.progress-bar {
  background-color: #5a8dee;
  color: #fff;
  box-shadow: 0 2px 4px 0 rgba(90, 141, 238, 0.4);
}

.modal-onboarding .carousel-indicators [data-bs-target] {
  background-color: #5a8dee;
}

.carousel-control-prev,
.carousel-control-next {
  color: #5a8dee;
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #5a8dee;
}

.list-group-item-primary {
  background-color: #e5edfc;
  color: #5a8dee !important;
}

a.list-group-item-primary,
button.list-group-item-primary {
  color: #5a8dee;
}
a.list-group-item-primary:hover, a.list-group-item-primary:focus,
button.list-group-item-primary:hover,
button.list-group-item-primary:focus {
  background-color: #dae1ef;
  color: #5a8dee;
}
a.list-group-item-primary.active,
button.list-group-item-primary.active {
  border-color: #5a8dee;
  background-color: #5a8dee;
  color: #5a8dee;
}

.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
  border-color: #5a8dee;
  background-color: #5a8dee;
}

.list-group.list-group-timeline .list-group-timeline-primary:before {
  background-color: #5a8dee;
}

.alert-primary {
  background-color: #e5edfc;
  border-color: #ceddfa;
  color: #5a8dee;
}
.alert-primary .btn-close {
  background-image: url("data:image/svg+xml,%3Csvg width='150px' height='151px' viewBox='0 0 150 151' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-1' points='131.251657 0 74.9933705 56.25 18.7483426 0 0 18.75 56.2450278 75 0 131.25 18.7483426 150 74.9933705 93.75 131.251657 150 150 131.25 93.7549722 75 150 18.75'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='🎨-%5BSetup%5D:-Colors-&amp;-Shadows' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard' transform='translate(-225.000000, -250.000000)'%3E%3Cg id='Icon-Color' transform='translate(225.000000, 250.500000)'%3E%3Cuse fill='%235a8dee' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='0.5' fill='%235a8dee' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.alert-primary .alert-link {
  color: #5a8dee;
}

.card .alert-primary hr {
  background-color: #5a8dee !important;
}

.alert-solid-primary {
  background-color: #5a8dee;
  color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(147, 158, 170, 0.4);
}
.alert-solid-primary .btn-close {
  background-image: url("data:image/svg+xml,%3Csvg width='150px' height='151px' viewBox='0 0 150 151' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-1' points='131.251657 0 74.9933705 56.25 18.7483426 0 0 18.75 56.2450278 75 0 131.25 18.7483426 150 74.9933705 93.75 131.251657 150 150 131.25 93.7549722 75 150 18.75'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='🎨-%5BSetup%5D:-Colors-&amp;-Shadows' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard' transform='translate(-225.000000, -250.000000)'%3E%3Cg id='Icon-Color' transform='translate(225.000000, 250.500000)'%3E%3Cuse fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='0.5' fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.alert-solid-primary .alert-link {
  color: #fff;
}

.card .alert-solid-primary hr {
  background-color: #fff !important;
}

.accordion.accordion-header-primary .accordion-item.active .accordion-button {
  color: #5a8dee !important;
}
.accordion.accordion-header-primary .accordion-item.active .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='m1.532 12 6.182-6-6.182-6L0 1.487 4.65 6 0 10.513z'/%3E%3C/defs%3E%3Cg transform='translate%282.571%29' fill='none' fill-rule='evenodd'%3E%3Cuse fill='%235a8dee' xlink:href='%23a'/%3E%3Cuse fill-opacity='.1' xlink:href='%23a'/%3E%3C/g%3E%3C/svg%3E%0A");
}

.tooltip-primary .tooltip-inner, .tooltip-primary > .tooltip .tooltip-inner, .ngb-tooltip-primary + ngb-tooltip-window .tooltip-inner {
  background: #5a8dee;
  color: #fff;
}
.tooltip-primary.bs-tooltip-top .tooltip-arrow::before, .tooltip-primary > .tooltip.bs-tooltip-top .tooltip-arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #5a8dee;
}
.tooltip-primary.bs-tooltip-start .tooltip-arrow::before, .tooltip-primary > .tooltip.bs-tooltip-start .tooltip-arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: #5a8dee;
}
[dir=rtl] .tooltip-primary.bs-tooltip-start .tooltip-arrow::before, [dir=rtl] .tooltip-primary > .tooltip.bs-tooltip-start .tooltip-arrow::before, [dir=rtl] .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-start .tooltip-arrow::before {
  border-right-color: #5a8dee;
}
.tooltip-primary.bs-tooltip-bottom .tooltip-arrow::before, .tooltip-primary > .tooltip.bs-tooltip-bottom .tooltip-arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: #5a8dee;
}
.tooltip-primary.bs-tooltip-end .tooltip-arrow::before, .tooltip-primary > .tooltip.bs-tooltip-end .tooltip-arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #5a8dee;
}
[dir=rtl] .tooltip-primary.bs-tooltip-end .tooltip-arrow::before, [dir=rtl] .tooltip-primary > .tooltip.bs-tooltip-end .tooltip-arrow::before, [dir=rtl] .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-end .tooltip-arrow::before {
  border-left-color: #5a8dee;
}

.popover.popover-primary, .popover .popover-primary > .popover, .popover .ngb-popover-primary + ngb-popover-window {
  border-color: transparent;
  background: #5a8dee;
}
.popover.popover-primary .popover-header, .popover .popover-primary > .popover .popover-header, .popover .ngb-popover-primary + ngb-popover-window .popover-header {
  background: transparent;
  color: #fff;
}
.popover.popover-primary .popover-body, .popover .popover-primary > .popover .popover-body, .popover .ngb-popover-primary + ngb-popover-window .popover-body {
  background: transparent;
  color: rgba(255, 255, 255, 0.8);
}
.popover.popover-primary > .popover-arrow::before, .popover .popover-primary > .popover > .popover-arrow::before, .popover .ngb-popover-primary + ngb-popover-window > .popover-arrow::before {
  border-color: transparent;
}
.popover.popover-primary.bs-popover-top > .popover-arrow::after, .popover .popover-primary > .popover.bs-popover-top > .popover-arrow::after, .popover .ngb-popover-primary + ngb-popover-window.bs-popover-top > .popover-arrow::after {
  border-top-color: #5a8dee;
}
.popover.popover-primary.bs-popover-end > .popover-arrow::after, .popover .popover-primary > .popover.bs-popover-end > .popover-arrow::after, .popover .ngb-popover-primary + ngb-popover-window.bs-popover-end > .popover-arrow::after {
  border-right-color: #5a8dee;
}
[dir=rtl] .popover.popover-primary.bs-popover-end > .popover-arrow::after, [dir=rtl] .popover .popover-primary > .popover.bs-popover-end > .popover-arrow::after, [dir=rtl] .popover .ngb-popover-primary + ngb-popover-window.bs-popover-end > .popover-arrow::after {
  border-left-color: #5a8dee;
}
.popover.popover-primary.bs-popover-bottom > .popover-arrow::after, .popover .popover-primary > .popover.bs-popover-bottom > .popover-arrow::after, .popover .ngb-popover-primary + ngb-popover-window.bs-popover-bottom > .popover-arrow::after {
  border-bottom-color: #5a8dee !important;
}
.popover.popover-primary.bs-popover-start > .popover-arrow::after, .popover .popover-primary > .popover.bs-popover-start > .popover-arrow::after, .popover .ngb-popover-primary + ngb-popover-window.bs-popover-start > .popover-arrow::after {
  border-left-color: #5a8dee;
}
[dir=rtl] .popover.popover-primary.bs-popover-start > .popover-arrow::after, [dir=rtl] .popover .popover-primary > .popover.bs-popover-start > .popover-arrow::after, [dir=rtl] .popover .ngb-popover-primary + ngb-popover-window.bs-popover-start > .popover-arrow::after {
  border-right-color: #5a8dee;
}
.popover.popover-primary.bs-popover-bottom .popover-header::before, .popover .popover-primary > .popover.bs-popover-bottom .popover-header::before, .popover .ngb-popover-primary + ngb-popover-window.bs-popover-bottom .popover-header::before {
  border-bottom: 1px solid transparent;
}

.table-primary {
  --bs-table-bg: #dee8fc;
  --bs-table-striped-bg: #d8e3f7;
  --bs-table-striped-color: #263c55;
  --bs-table-active-bg: #ccd7eb;
  --bs-table-active-color: #263c55;
  --bs-table-hover-bg: #d3def2;
  --bs-table-hover-color: #263c55;
  color: #263c55;
  border-color: #b9c6db;
}
.table-primary .btn-icon {
  color: #263c55;
}

.btn-primary {
  color: #fff;
  background-color: #5a8dee;
  border-color: #5a8dee;
  box-shadow: 0 0.125rem 0.25rem rgba(147, 158, 170, 0.4);
}
.btn-primary:hover {
  color: #fff;
  background-color: #7ba4f1;
  border-color: #7ba4f1;
  box-shadow: 0 0.25rem 1rem rgba(147, 158, 170, 0.45);
}
.btn-check:focus + .btn-primary, .btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #4d78ca;
  border-color: #4d78ca;
  box-shadow: none;
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #4d78ca;
  border-color: #4d78ca;
}
.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #5a8dee;
  border-color: #5a8dee;
  box-shadow: none !important;
}

.btn-label-primary {
  color: #5a8dee;
  border-color: transparent;
  background: #e5edfc;
}
.btn-label-primary:hover {
  border-color: transparent;
  background: #5a8dee;
  color: #fff;
}
.btn-label-primary:focus, .btn-label-primary.focus {
  color: #fff;
  background: #4d78ca;
  box-shadow: none !important;
}
.btn-label-primary:active, .btn-label-primary.active, .show > .btn-label-primary.dropdown-toggle {
  color: #fff;
  background-color: #4d78ca;
  border-color: transparent;
}
.btn-label-primary:active:focus, .btn-label-primary.active:focus, .show > .btn-label-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-label-primary.disabled, .btn-label-primary:disabled {
  color: #5a8dee;
  border-color: transparent;
  background: #e5edfc;
  box-shadow: none;
}

.btn-outline-primary {
  color: #5a8dee;
  border-color: #5a8dee;
  background: transparent;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #5a8dee;
  border-color: #5a8dee;
}
.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  color: #fff;
  background-color: #4d78ca;
  border-color: #4d78ca;
  box-shadow: none;
}
.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #4d78ca;
  border-color: #4d78ca;
}
.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: none;
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #5a8dee;
  background-color: transparent;
}

.btn-outline-primary .badge {
  background: #5a8dee;
  border-color: #5a8dee;
  color: #fff;
}

.btn-outline-primary:hover .badge,
.btn-outline-primary:focus:hover .badge,
.btn-outline-primary:active .badge,
.btn-outline-primary.active .badge,
.show > .btn-outline-primary.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #5a8dee;
}

.dropdown-item:not(.disabled).active,
.dropdown-item:not(.disabled):active {
  background-color: rgba(90, 141, 238, 0.08);
  color: #5a8dee !important;
}

.dropdown-menu > li:not(.disabled) > a:not(.dropdown-item):active,
.dropdown-menu > li.active:not(.disabled) > a:not(.dropdown-item) {
  background-color: rgba(90, 141, 238, 0.08);
  color: #5a8dee !important;
}

.nav .nav-link:hover, .nav .nav-link:focus {
  color: #517fd6;
}

.nav-pills .nav-link.active, .nav-pills .nav-link.active:hover, .nav-pills .nav-link.active:focus {
  background-color: #5a8dee;
  color: #fff;
}

.tabs-line.nav-tabs .nav-link.active, .tabs-line.nav-tabs .nav-link.active:hover, .tabs-line.nav-tabs .nav-link.active:focus,
.tabs-line > .nav-tabs .nav-link.active,
.tabs-line > .nav-tabs .nav-link.active:hover,
.tabs-line > .nav-tabs .nav-link.active:focus {
  box-shadow: 0 -2px 0 #5a8dee inset;
}

.nav-align-bottom .tabs-line.nav-tabs .nav-link.active, .nav-align-bottom .tabs-line.nav-tabs .nav-link.active:hover, .nav-align-bottom .tabs-line.nav-tabs .nav-link.active:focus,
.nav-align-bottom .tabs-line > .nav-tabs .nav-link.active,
.nav-align-bottom .tabs-line > .nav-tabs .nav-link.active:hover,
.nav-align-bottom .tabs-line > .nav-tabs .nav-link.active:focus {
  box-shadow: 0 2px 0 #5a8dee inset;
}

.nav-align-left .tabs-line.nav-tabs .nav-link.active, .nav-align-left .tabs-line.nav-tabs .nav-link.active:hover, .nav-align-left .tabs-line.nav-tabs .nav-link.active:focus,
.nav-align-left .tabs-line > .nav-tabs .nav-link.active,
.nav-align-left .tabs-line > .nav-tabs .nav-link.active:hover,
.nav-align-left .tabs-line > .nav-tabs .nav-link.active:focus {
  box-shadow: -2px 0px 0 #5a8dee inset;
}

.nav-align-right .tabs-line.nav-tabs .nav-link.active, .nav-align-right .tabs-line.nav-tabs .nav-link.active:hover, .nav-align-right .tabs-line.nav-tabs .nav-link.active:focus,
.nav-align-right .tabs-line > .nav-tabs .nav-link.active,
.nav-align-right .tabs-line > .nav-tabs .nav-link.active:hover,
.nav-align-right .tabs-line > .nav-tabs .nav-link.active:focus {
  box-shadow: 2px 0px 0 #5a8dee inset;
}

[dir=rtl] .nav-align-left .tabs-line.nav-tabs .nav-link.active, [dir=rtl] .nav-align-left .tabs-line.nav-tabs .nav-link.active:hover, [dir=rtl] .nav-align-left .tabs-line.nav-tabs .nav-link.active:focus,
[dir=rtl] .nav-align-left .tabs-line > .nav-tabs .nav-link.active,
[dir=rtl] .nav-align-left .tabs-line > .nav-tabs .nav-link.active:hover,
[dir=rtl] .nav-align-left .tabs-line > .nav-tabs .nav-link.active:focus {
  box-shadow: 2px 0px 0 #5a8dee inset;
}
[dir=rtl] .nav-align-right .tabs-line.nav-tabs .nav-link.active, [dir=rtl] .nav-align-right .tabs-line.nav-tabs .nav-link.active:hover, [dir=rtl] .nav-align-right .tabs-line.nav-tabs .nav-link.active:focus,
[dir=rtl] .nav-align-right .tabs-line > .nav-tabs .nav-link.active,
[dir=rtl] .nav-align-right .tabs-line > .nav-tabs .nav-link.active:hover,
[dir=rtl] .nav-align-right .tabs-line > .nav-tabs .nav-link.active:focus {
  box-shadow: -2px 0px 0 #5a8dee inset;
}

.form-control:focus,
.form-select:focus {
  border-color: #5a8dee;
}

.input-group:focus-within .form-control,
.input-group:focus-within .input-group-text {
  border-color: #5a8dee;
}

.form-check-input:focus {
  border-color: #5a8dee;
}
.form-check-input:checked {
  background-color: #5a8dee;
  border-color: #5a8dee;
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #5a8dee;
  border-color: #5a8dee;
}

.custom-option.checked {
  border: 1px solid #5a8dee;
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%235a8dee'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-control:focus ~ .form-label {
  border-color: #5a8dee;
}
.form-control:focus ~ .form-label::after {
  border-color: inherit;
}

.switch-input:checked ~ .switch-toggle-slider {
  background: #5a8dee;
  color: #fff;
}

.switch-input:active ~ .switch-toggle-slider {
  box-shadow: none;
}

.switch-input:focus ~ .switch-toggle-slider {
  box-shadow: 0 0 0 0.12rem rgba(147, 158, 170, 0.3);
}

.switch-primary.switch .switch-input:checked ~ .switch-toggle-slider {
  background: #5a8dee;
  color: #fff;
}

.switch-primary.switch .switch-input:active ~ .switch-toggle-slider {
  box-shadow: none;
}

.switch-primary.switch .switch-input:focus ~ .switch-toggle-slider {
  box-shadow: 0 0 0 0.12rem rgba(147, 158, 170, 0.3);
}

.timeline .timeline-item-primary .timeline-event {
  background-color: rgba(90, 141, 238, 0.1);
}
.timeline .timeline-item-primary .timeline-event:before {
  border-left-color: rgba(90, 141, 238, 0.1) !important;
  border-right-color: rgba(90, 141, 238, 0.1) !important;
}

.timeline .timeline-point-primary {
  background-color: #5a8dee !important;
  box-shadow: 0 0 0 0.1875rem rgba(90, 141, 238, 0.16);
}

.timeline .timeline-indicator-primary {
  border-color: #5a8dee !important;
}
.timeline .timeline-indicator-primary i {
  color: #5a8dee !important;
}

.divider.divider-primary .divider-text:before, .divider.divider-primary .divider-text:after {
  border-color: #5a8dee;
}

.navbar.bg-primary {
  background-color: #5a8dee !important;
  color: #e0eafc;
}
.navbar.bg-primary .navbar-brand,
.navbar.bg-primary .navbar-brand a {
  color: #fff;
}
.navbar.bg-primary .navbar-brand:hover, .navbar.bg-primary .navbar-brand:focus,
.navbar.bg-primary .navbar-brand a:hover,
.navbar.bg-primary .navbar-brand a:focus {
  color: #fff;
}
.navbar.bg-primary .navbar-search-wrapper .navbar-search-icon,
.navbar.bg-primary .navbar-search-wrapper .search-input {
  color: #e0eafc;
}
.navbar.bg-primary .search-input-wrapper .search-input,
.navbar.bg-primary .search-input-wrapper .search-toggler {
  background-color: #5a8dee !important;
  color: #e0eafc;
}
.navbar.bg-primary .navbar-nav > .nav-link,
.navbar.bg-primary .navbar-nav > .nav-item > .nav-link,
.navbar.bg-primary .navbar-nav > .nav > .nav-item > .nav-link {
  color: #e0eafc;
}
.navbar.bg-primary .navbar-nav > .nav-link:hover, .navbar.bg-primary .navbar-nav > .nav-link:focus,
.navbar.bg-primary .navbar-nav > .nav-item > .nav-link:hover,
.navbar.bg-primary .navbar-nav > .nav-item > .nav-link:focus,
.navbar.bg-primary .navbar-nav > .nav > .nav-item > .nav-link:hover,
.navbar.bg-primary .navbar-nav > .nav > .nav-item > .nav-link:focus {
  color: #fff;
}
.navbar.bg-primary .navbar-nav > .nav-link.disabled,
.navbar.bg-primary .navbar-nav > .nav-item > .nav-link.disabled,
.navbar.bg-primary .navbar-nav > .nav > .nav-item > .nav-link.disabled {
  color: #aac5f6 !important;
}
.navbar.bg-primary .navbar-nav .show > .nav-link,
.navbar.bg-primary .navbar-nav .active > .nav-link,
.navbar.bg-primary .navbar-nav .nav-link.show,
.navbar.bg-primary .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar.bg-primary .navbar-toggler {
  color: #e0eafc;
  border-color: rgba(255, 255, 255, 0.15);
}
.navbar.bg-primary .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 0.8)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}
.navbar.bg-primary .navbar-text {
  color: #e0eafc;
}
.navbar.bg-primary .navbar-text a {
  color: #fff;
}
.navbar.bg-primary .navbar-text a:hover, .navbar.bg-primary .navbar-text a:focus {
  color: #fff;
}
.navbar.bg-primary hr {
  border-color: rgba(255, 255, 255, 0.15);
}

.menu.bg-primary {
  background-color: #5a8dee !important;
  color: #e0eafc;
}
.menu.bg-primary .menu-link,
.menu.bg-primary .menu-horizontal-prev,
.menu.bg-primary .menu-horizontal-next {
  color: #e0eafc;
}
.menu.bg-primary .menu-link.active,
.menu.bg-primary .menu-horizontal-prev.active,
.menu.bg-primary .menu-horizontal-next.active {
  color: #fff;
}
.menu.bg-primary .menu-item.disabled .menu-link,
.menu.bg-primary .menu-horizontal-prev.disabled,
.menu.bg-primary .menu-horizontal-next.disabled {
  color: #aac5f6 !important;
}
.menu.bg-primary .menu-item.active > .menu-link:not(.menu-toggle) {
  color: #fff;
  background-color: #5e90ee;
}
.menu.bg-primary.menu-horizontal .menu-inner > .menu-item.active > .menu-link {
  background-color: #5e90ee !important;
}
.menu.bg-primary.menu-horizontal .menu-sub > .menu-item.active > .menu-link:not(.menu-toggle) {
  background-color: transparent !important;
  color: #fff !important;
}
.menu.bg-primary .menu-inner > .menu-item.menu-item-closing .menu-item.open .menu-sub,
.menu.bg-primary .menu-inner > .menu-item.menu-item-closing .menu-item.open .menu-toggle {
  background: transparent;
  color: #e0eafc;
}
.menu.bg-primary .menu-inner-shadow {
  background: linear-gradient(#5a8dee 41%, rgba(90, 141, 238, 0.11) 95%, rgba(90, 141, 238, 0));
}
.menu.bg-primary .menu-text {
  color: #fff;
}
.menu.bg-primary .menu-header {
  color: #bfd3f9;
}
.menu.bg-primary hr,
.menu.bg-primary .menu-divider,
.menu.bg-primary .menu-inner > .menu-item.open > .menu-sub::before {
  border-color: rgba(255, 255, 255, 0.15) !important;
}
.menu.bg-primary .menu-inner > .menu-header::before {
  background-color: rgba(255, 255, 255, 0.15);
}
.menu.bg-primary .menu-block::before {
  background-color: #bfd3f9;
}
.menu.bg-primary .menu-inner > .menu-item.open .menu-item.open > .menu-toggle::before {
  background-color: #76a1f1;
}
.menu.bg-primary .menu-inner > .menu-item.open .menu-item.active > .menu-link::before {
  background-color: #fff;
}
.menu.bg-primary .ps__thumb-y,
.menu.bg-primary .ps__rail-y.ps--clicking > .ps__thumb-y {
  background: rgba(255, 255, 255, 0.614602353) !important;
}

.footer.bg-primary {
  background-color: #5a8dee !important;
  color: #e0eafc;
}
.footer.bg-primary .footer-link {
  color: #e0eafc;
}
.footer.bg-primary .footer-link:hover, .footer.bg-primary .footer-link:focus {
  color: #fff;
}
.footer.bg-primary .footer-link.disabled {
  color: #aac5f6 !important;
}
.footer.bg-primary .footer-text {
  color: #fff;
}
.footer.bg-primary .show > .footer-link,
.footer.bg-primary .active > .footer-link,
.footer.bg-primary .footer-link.show,
.footer.bg-primary .footer-link.active {
  color: #fff;
}
.footer.bg-primary hr {
  border-color: rgba(255, 255, 255, 0.15);
}

.toast .toast-header.bg-primary,
.bs-toast .toast-header.bg-primary {
  background-color: #5a8dee;
  color: #fff;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  color: #5a8dee;
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: #5a8dee;
}

.svg-illustration svg {
  fill: #5a8dee;
}

.jstree-default .jstree-wholerow-clicked,
.jstree-default .jstree-wholerow-hovered {
  background: #e6eefc;
}

.jstree-default-dark .jstree-wholerow-hovered,
.jstree-default-dark .jstree-wholerow-clicked {
  background: #516ea2;
}

html:not([dir=rtl]) .border-primary,
html[dir=rtl] .border-primary {
  border-color: #5a8dee !important;
}

a {
  color: #5a8dee;
}
a:hover {
  color: #6b98f0;
}

.fill-primary {
  fill: #5a8dee;
}

.noUi-primary .noUi-base .noUi-connect {
  background: #5a8dee !important;
}
.noUi-primary .noUi-base .noUi-touch-area {
  background-color: #5a8dee !important;
}

.select2-container--default.select2-container--focus .select2-selection, .select2-container--default.select2-container--open .select2-selection {
  border-color: #5a8dee !important;
}

.select2-primary .select2-container--default .select2-selection--multiple .select2-selection__choice {
  background: rgba(90, 141, 238, 0.08) !important;
  color: #5a8dee !important;
}
.select2-primary .select2-container--default .select2-selection--multiple .select2-selection__choice:hover {
  background: rgba(90, 141, 238, 0.16) !important;
}

.tagify--focus {
  border-color: #5a8dee !important;
}

table.dataTable.dtr-column > tbody > tr > td.control:before,
table.dataTable.dtr-column > tbody > tr > th.control:before {
  background-color: #5a8dee;
  border: 2px solid #fff;
  box-shadow: 0 0 3px rgba(38, 60, 85, 0.8);
}

.bootstrap-select .dropdown-menu.inner a[aria-selected=true] {
  background: rgba(90, 141, 238, 0.08) !important;
  color: #5a8dee !important;
}
.bootstrap-select .dropdown-toggle.show {
  border-color: #5a8dee;
}

.datepicker table tr td.active, .datepicker table tr td.active.highlighted, .datepicker table tr td.active,
.datepicker table tr td span.active,
.datepicker table tr td span.active.disabled, .datepicker table tr td.range-start, .datepicker table tr td.range-end {
  background: #5a8dee !important;
  color: #fff !important;
}
.datepicker table tr td.range, .datepicker table tr td.range.highlighted, .datepicker table tr td.range.today {
  color: #5a8dee !important;
  background: #f2f6fe !important;
}
.datepicker table tr td.range.focused, .datepicker table tr td.range.highlighted.focused, .datepicker table tr td.range.today.focused {
  background: #d6e2fc !important;
}
.datepicker table tr td.range.disabled, .datepicker table tr td.range.highlighted.disabled, .datepicker table tr td.range.today.disabled {
  background: rgba(242, 246, 254, 0.5) !important;
  color: rgba(38, 60, 85, 0.5) !important;
}
.datepicker table tr td.today:not(.active) {
  border: 1px solid #5a8dee;
}
.datepicker table th.prev, .datepicker table th.next {
  border: 1px solid #5a8dee;
}
.datepicker table th.prev::after, .datepicker table th.next::after {
  color: #5a8dee;
  border-color: #5a8dee;
}

.flatpickr-calendar .numInputWrapper span.arrowDown:after {
  border-top-color: #fff;
}
.flatpickr-calendar .numInputWrapper span.arrowUp:after {
  border-bottom-color: #fff;
}

.flatpickr-day.today {
  border-color: #5a8dee;
}
.flatpickr-day.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange {
  color: #5a8dee !important;
  background: #f2f6fe !important;
  border-color: #f2f6fe !important;
}
.flatpickr-day.selected, .flatpickr-day.selected.inRange, .flatpickr-day.selected:focus, .flatpickr-day.selected:hover, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange, .flatpickr-day.startRange.inRange, .flatpickr-day.startRange:focus, .flatpickr-day.startRange:hover, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange, .flatpickr-day.endRange.inRange, .flatpickr-day.endRange:focus, .flatpickr-day.endRange:hover, .flatpickr-day.endRange.nextMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.week.selected {
  color: #fff !important;
  background: #5a8dee !important;
  border-color: #5a8dee !important;
}

.flatpickr-prev-month,
.flatpickr-next-month {
  border: 1px solid #5a8dee;
}
.flatpickr-prev-month svg,
.flatpickr-next-month svg {
  fill: #5a8dee;
  stroke: #5a8dee;
}

.daterangepicker table thead th select,
.daterangepicker table thead td select {
  background-color: transparent;
}

.daterangepicker td.active:not(.off) {
  background: #5a8dee !important;
  color: #fff;
}

.daterangepicker .calendar-table th.prev,
.daterangepicker .calendar-table th.next,
.daterangepicker .calendar-table th.prev:hover,
.daterangepicker .calendar-table th.next:hover {
  border: 1px solid #5a8dee;
}
.daterangepicker .calendar-table th.prev span,
.daterangepicker .calendar-table th.next span,
.daterangepicker .calendar-table th.prev:hover span,
.daterangepicker .calendar-table th.next:hover span {
  border-color: #5a8dee;
}

.daterangepicker .start-date:not(.end-date):not(.off),
.daterangepicker .end-date:not(.start-date):not(.off) {
  background-color: #5a8dee;
  color: #fff;
  border: 0 !important;
}
.daterangepicker .start-date:not(.end-date):not(.off):hover,
.daterangepicker .end-date:not(.start-date):not(.off):hover {
  background-color: #5a8dee !important;
}

.daterangepicker .input-mini.active {
  border-color: #5a8dee !important;
}

.daterangepicker td.in-range:not(.start-date):not(.end-date):not(.off) {
  color: #5a8dee !important;
  background-color: #f2f6fe !important;
}

.ranges li.active {
  color: #5a8dee !important;
  background-color: #f2f6fe !important;
}

li.ui-timepicker-selected,
.ui-timepicker-list .ui-timepicker-selected:hover {
  color: #5a8dee !important;
  background: rgba(90, 141, 238, 0.08) !important;
}

.ql-snow.ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar button:hover,
.ql-snow .ql-toolbar button:focus,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #5a8dee !important;
}
.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button:focus .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:focus .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: #5a8dee !important;
}
.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: #5a8dee !important;
}

.tt-suggestion:active,
.tt-cursor {
  background: rgba(90, 141, 238, 0.08) !important;
  color: #5a8dee !important;
}

.dropzone.dz-drag-hover {
  border-color: #5a8dee !important;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active,
.swiper-pagination.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #5a8dee !important;
}

.sk-primary.sk-plane,
.sk-primary .sk-chase-dot:before,
.sk-primary .sk-bounce-dot,
.sk-primary .sk-wave-rect,
.sk-primary.sk-pulse,
.sk-primary .sk-swing-dot,
.sk-primary .sk-circle-dot:before,
.sk-primary .sk-circle-fade-dot:before,
.sk-primary .sk-grid-cube,
.sk-primary .sk-fold-cube:before {
  background-color: #5a8dee;
}

.plyr input[type=range]::-ms-fill-lower {
  background: #5a8dee !important;
}

.plyr input[type=range]:active::-webkit-slider-thumb {
  background: #5a8dee !important;
}
.plyr input[type=range]:active::-moz-range-thumb {
  background: #5a8dee !important;
}
.plyr input[type=range]:active::-ms-thumb {
  background: #5a8dee !important;
}

.plyr--video .plyr__control.plyr__control--overlaid,
.plyr--video .plyr__controls button.tab-focus:focus,
.plyr--video .plyr__control[aria-expanded=true],
.plyr--video .plyr__controls button:hover {
  background: #5a8dee !important;
  color: #fff !important;
}

.plyr--audio .plyr__controls button.tab-focus:focus,
.plyr--audio .plyr__control[aria-expanded=true],
.plyr--audio .plyr__controls button:hover {
  background: #5a8dee !important;
  color: #fff !important;
}

.plyr__play-large {
  background: #5a8dee !important;
  color: #fff !important;
}

.plyr__progress--played,
.plyr__volume--display {
  color: #5a8dee !important;
}

.plyr--full-ui input[type=range] {
  color: #5a8dee !important;
}

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
  background: #5a8dee !important;
}

.fc .fc-event-primary:not(.fc-list-event) {
  background-color: #e5edfc !important;
  color: #5a8dee !important;
}
.fc .fc-event-primary.fc-list-event .fc-list-event-dot {
  border-color: #5a8dee !important;
}
.fc .fc-button-primary:not(.fc-prev-button):not(.fc-next-button) {
  color: #5a8dee;
  border-color: transparent;
  background: #e5edfc;
}
.fc .fc-button-primary:not(.fc-prev-button):not(.fc-next-button):hover {
  border-color: transparent;
  background: #5a8dee;
  color: #fff;
}
.fc .fc-button-primary:not(.fc-prev-button):not(.fc-next-button):focus, .fc .fc-button-primary:not(.fc-prev-button):not(.fc-next-button).focus {
  color: #fff;
  background: #4d78ca;
  box-shadow: none !important;
}
.fc .fc-button-primary:not(.fc-prev-button):not(.fc-next-button):active, .fc .fc-button-primary:not(.fc-prev-button):not(.fc-next-button).active, .show > .fc .fc-button-primary:not(.fc-prev-button):not(.fc-next-button).dropdown-toggle {
  color: #fff;
  background-color: #4d78ca;
  border-color: transparent;
}
.fc .fc-button-primary:not(.fc-prev-button):not(.fc-next-button):active:focus, .fc .fc-button-primary:not(.fc-prev-button):not(.fc-next-button).active:focus, .show > .fc .fc-button-primary:not(.fc-prev-button):not(.fc-next-button).dropdown-toggle:focus {
  box-shadow: none;
}
.fc .fc-button-primary:not(.fc-prev-button):not(.fc-next-button).disabled, .fc .fc-button-primary:not(.fc-prev-button):not(.fc-next-button):disabled {
  color: #5a8dee;
  border-color: transparent;
  background: #e5edfc;
  box-shadow: none;
}
.fc .fc-button-primary:not(.fc-prev-button):not(.fc-next-button).fc-button-active {
  background-color: #5a8dee !important;
  border-color: #5a8dee !important;
  color: #fff;
}

.swal2-progress-steps[class] .swal2-progress-step.swal2-active-progress-step,
.swal2-progress-steps[class] .swal2-progress-step-line,
.swal2-progress-steps[class] .swal2-active-progress-step,
.swal2-progress-steps[class] .swal2-progress-step {
  background: #5a8dee;
  color: #fff;
}

.swal2-progress-steps[class] .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step,
.swal2-progress-steps[class] .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step-line {
  background: #e6eefc;
}

.pcr-app .pcr-type.active,
.pcr-app .pcr-save {
  background: #5a8dee !important;
}

.icon-card.active {
  outline: 1px solid #5a8dee;
}
.icon-card.active i,
.icon-card.active svg {
  color: #5a8dee;
}

.shepherd-element[data-popper-placement=bottom] .shepherd-arrow:before {
  background-color: #5a8dee !important;
  border-color: #5a8dee;
}
.shepherd-element .shepherd-header {
  background: #5a8dee !important;
  color: #fff;
}
.shepherd-element .shepherd-header .shepherd-title,
.shepherd-element .shepherd-header .shepherd-cancel-icon {
  color: #fff !important;
}
.shepherd-element .shepherd-button:not(:disabled).btn-primary {
  color: #fff;
  background-color: #5a8dee;
  border-color: #5a8dee;
  box-shadow: 0 0.125rem 0.25rem rgba(147, 158, 170, 0.4);
}
.shepherd-element .shepherd-button:not(:disabled).btn-primary:hover {
  color: #fff;
  background-color: #7ba4f1;
  border-color: #7ba4f1;
  box-shadow: 0 0.25rem 1rem rgba(147, 158, 170, 0.45);
}
.btn-check:focus + .shepherd-element .shepherd-button:not(:disabled).btn-primary, .shepherd-element .shepherd-button:not(:disabled).btn-primary:focus, .shepherd-element .shepherd-button:not(:disabled).btn-primary.focus {
  color: #fff;
  background-color: #4d78ca;
  border-color: #4d78ca;
  box-shadow: none;
}
.btn-check:checked + .shepherd-element .shepherd-button:not(:disabled).btn-primary, .btn-check:active + .shepherd-element .shepherd-button:not(:disabled).btn-primary, .shepherd-element .shepherd-button:not(:disabled).btn-primary:active, .shepherd-element .shepherd-button:not(:disabled).btn-primary.active, .show > .shepherd-element .shepherd-button:not(:disabled).btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #4d78ca;
  border-color: #4d78ca;
}
.btn-check:checked + .shepherd-element .shepherd-button:not(:disabled).btn-primary:focus, .btn-check:active + .shepherd-element .shepherd-button:not(:disabled).btn-primary:focus, .shepherd-element .shepherd-button:not(:disabled).btn-primary:active:focus, .shepherd-element .shepherd-button:not(:disabled).btn-primary.active:focus, .show > .shepherd-element .shepherd-button:not(:disabled).btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.shepherd-element .shepherd-button:not(:disabled).btn-primary.disabled, .shepherd-element .shepherd-button:not(:disabled).btn-primary:disabled {
  color: #fff;
  background-color: #5a8dee;
  border-color: #5a8dee;
  box-shadow: none !important;
}
.shepherd-element .shepherd-button:not(:disabled).btn-label-secondary {
  color: #69809a;
  border-color: transparent;
  background: #e7ebef;
}
.shepherd-element .shepherd-button:not(:disabled).btn-label-secondary:hover {
  border-color: transparent;
  background: #69809a;
  color: #fff;
}
.shepherd-element .shepherd-button:not(:disabled).btn-label-secondary:focus, .shepherd-element .shepherd-button:not(:disabled).btn-label-secondary.focus {
  color: #fff;
  background: #596d83;
  box-shadow: none !important;
}
.shepherd-element .shepherd-button:not(:disabled).btn-label-secondary:active, .shepherd-element .shepherd-button:not(:disabled).btn-label-secondary.active, .show > .shepherd-element .shepherd-button:not(:disabled).btn-label-secondary.dropdown-toggle {
  color: #fff;
  background-color: #596d83;
  border-color: transparent;
}
.shepherd-element .shepherd-button:not(:disabled).btn-label-secondary:active:focus, .shepherd-element .shepherd-button:not(:disabled).btn-label-secondary.active:focus, .show > .shepherd-element .shepherd-button:not(:disabled).btn-label-secondary.dropdown-toggle:focus {
  box-shadow: none;
}
.shepherd-element .shepherd-button:not(:disabled).btn-label-secondary.disabled, .shepherd-element .shepherd-button:not(:disabled).btn-label-secondary:disabled {
  color: #69809a;
  border-color: transparent;
  background: #e7ebef;
  box-shadow: none;
}

.bs-stepper .step.active .bs-stepper-circle {
  background-color: #5a8dee !important;
  color: #fff;
}
.bs-stepper .step.active .bs-stepper-label {
  color: #5a8dee !important;
}
.bs-stepper .step.active .bs-stepper-icon svg {
  fill: #5a8dee !important;
}
.bs-stepper .step.active .step-trigger::after {
  background-color: #5a8dee;
}
.bs-stepper .step.crossed .step-trigger .bs-stepper-circle {
  border-color: #5a8dee !important;
  color: #5a8dee !important;
}
.bs-stepper .step.crossed .step-trigger .bs-stepper-icon svg {
  fill: #5a8dee !important;
}
.bs-stepper .step.crossed .step-trigger::after {
  background-color: #5a8dee;
}
.bs-stepper .step.crossed + .line::before {
  background-color: #5a8dee !important;
}
.bs-stepper .step.crossed + .line svg {
  fill: #5a8dee;
}
.bs-stepper .step.crossed + .line i {
  color: #5a8dee;
}
.bs-stepper.wizard-icons .step.crossed .step-trigger .bs-stepper-label {
  color: #5a8dee !important;
}
.bs-stepper .step-trigger:focus {
  color: #5a8dee;
}
.bs-stepper .step-trigger:focus svg {
  fill: #5a8dee !important;
}

.bg-navbar-theme {
  background-color: #fff !important;
  color: #677788;
}
.bg-navbar-theme .navbar-brand,
.bg-navbar-theme .navbar-brand a {
  color: #516377;
}
.bg-navbar-theme .navbar-brand:hover, .bg-navbar-theme .navbar-brand:focus,
.bg-navbar-theme .navbar-brand a:hover,
.bg-navbar-theme .navbar-brand a:focus {
  color: #516377;
}
.bg-navbar-theme .navbar-search-wrapper .navbar-search-icon,
.bg-navbar-theme .navbar-search-wrapper .search-input {
  color: #677788;
}
.bg-navbar-theme .search-input-wrapper .search-input,
.bg-navbar-theme .search-input-wrapper .search-toggler {
  background-color: #fff !important;
  color: #677788;
}
.bg-navbar-theme .navbar-nav > .nav-link,
.bg-navbar-theme .navbar-nav > .nav-item > .nav-link,
.bg-navbar-theme .navbar-nav > .nav > .nav-item > .nav-link {
  color: #677788;
}
.bg-navbar-theme .navbar-nav > .nav-link:hover, .bg-navbar-theme .navbar-nav > .nav-link:focus,
.bg-navbar-theme .navbar-nav > .nav-item > .nav-link:hover,
.bg-navbar-theme .navbar-nav > .nav-item > .nav-link:focus,
.bg-navbar-theme .navbar-nav > .nav > .nav-item > .nav-link:hover,
.bg-navbar-theme .navbar-nav > .nav > .nav-item > .nav-link:focus {
  color: #516377;
}
.bg-navbar-theme .navbar-nav > .nav-link.disabled,
.bg-navbar-theme .navbar-nav > .nav-item > .nav-link.disabled,
.bg-navbar-theme .navbar-nav > .nav > .nav-item > .nav-link.disabled {
  color: #a4adb8 !important;
}
.bg-navbar-theme .navbar-nav .show > .nav-link,
.bg-navbar-theme .navbar-nav .active > .nav-link,
.bg-navbar-theme .navbar-nav .nav-link.show,
.bg-navbar-theme .navbar-nav .nav-link.active {
  color: #516377;
}
.bg-navbar-theme .navbar-toggler {
  color: #677788;
  border-color: rgba(81, 99, 119, 0.075);
}
.bg-navbar-theme .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba(38, 60, 85, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}
.bg-navbar-theme .navbar-text {
  color: #677788;
}
.bg-navbar-theme .navbar-text a {
  color: #516377;
}
.bg-navbar-theme .navbar-text a:hover, .bg-navbar-theme .navbar-text a:focus {
  color: #516377;
}
.bg-navbar-theme hr {
  border-color: rgba(81, 99, 119, 0.075);
}

.layout-horizontal .layout-navbar {
  background-color: #fff !important;
  box-shadow: 0 1px 0 #d4d8dd;
}

.layout-navbar-fixed .layout-navbar.navbar-elevated {
  box-shadow: 0 1px 0 #dee1e4;
}

.bg-menu-theme {
  background-color: #fff !important;
  color: #677788;
}
.bg-menu-theme .menu-link,
.bg-menu-theme .menu-horizontal-prev,
.bg-menu-theme .menu-horizontal-next {
  color: #677788;
}
.bg-menu-theme .menu-link.active,
.bg-menu-theme .menu-horizontal-prev.active,
.bg-menu-theme .menu-horizontal-next.active {
  color: #5a8dee;
}
.bg-menu-theme .menu-item.disabled .menu-link,
.bg-menu-theme .menu-horizontal-prev.disabled,
.bg-menu-theme .menu-horizontal-next.disabled {
  color: #a4adb8 !important;
}
.bg-menu-theme .menu-item.active > .menu-link:not(.menu-toggle) {
  color: #5a8dee;
  background-color: #e5edfc;
}
.bg-menu-theme.menu-horizontal .menu-inner > .menu-item.active > .menu-link {
  background-color: #e5edfc !important;
}
.bg-menu-theme.menu-horizontal .menu-sub > .menu-item.active > .menu-link:not(.menu-toggle) {
  background-color: transparent !important;
  color: #5a8dee !important;
}
.bg-menu-theme .menu-inner > .menu-item.menu-item-closing .menu-item.open .menu-sub,
.bg-menu-theme .menu-inner > .menu-item.menu-item-closing .menu-item.open .menu-toggle {
  background: transparent;
  color: #677788;
}
.bg-menu-theme .menu-inner-shadow {
  background: linear-gradient(#fff 41%, rgba(255, 255, 255, 0.11) 95%, rgba(255, 255, 255, 0));
}
.bg-menu-theme .menu-text {
  color: #5a8dee;
}
.bg-menu-theme .menu-header {
  color: #8d99a6;
}
.bg-menu-theme hr,
.bg-menu-theme .menu-divider,
.bg-menu-theme .menu-inner > .menu-item.open > .menu-sub::before {
  border-color: transparent !important;
}
.bg-menu-theme .menu-inner > .menu-header::before {
  background-color: transparent;
}
.bg-menu-theme .menu-block::before {
  background-color: #8d99a6;
}
.bg-menu-theme .menu-inner > .menu-item.open .menu-item.open > .menu-toggle::before {
  background-color: #e5edfc;
}
.bg-menu-theme .menu-inner > .menu-item.open .menu-item.active > .menu-link::before {
  background-color: #5a8dee;
}
.bg-menu-theme .ps__thumb-y,
.bg-menu-theme .ps__rail-y.ps--clicking > .ps__thumb-y {
  background: rgba(90, 141, 238, 0.2) !important;
}

.layout-menu {
  box-shadow: 0 0 0 1px #d4d8dd;
}

.layout-menu-horizontal {
  box-shadow: 0 -1px 0 #d4d8dd inset;
}

.bg-menu-theme .menu-header {
  color: #a8b1bb;
}
.bg-menu-theme .menu-inner .menu-item.open > .menu-link:hover,
.bg-menu-theme .menu-inner .menu-item:not(.active) .menu-link:hover {
  background-color: rgba(38, 60, 85, 0.06);
}
.bg-menu-theme .menu-inner .menu-sub > .menu-item.active > .menu-link.menu-toggle,
.bg-menu-theme .menu-inner .menu-sub > .menu-item.open > .menu-link.menu-toggle {
  background-color: rgba(38, 60, 85, 0.06);
}
.bg-menu-theme .menu-inner .menu-sub > .menu-item.active > .menu-link.menu-toggle .menu-icon,
.bg-menu-theme .menu-inner .menu-sub > .menu-item.open > .menu-link.menu-toggle .menu-icon {
  color: #5a8dee;
}
html:not(.layout-menu-collapsed) .bg-menu-theme .menu-inner > .menu-item.open:not(.active), .layout-menu-hover.layout-menu-collapsed .bg-menu-theme .menu-inner > .menu-item.open:not(.active) {
  background-color: #fafafa;
}
.bg-menu-theme .menu-inner > .menu-item.active {
  background-color: #fafafa;
}
.layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-expanded) .bg-menu-theme .menu-inner > .menu-item.active {
  background-color: #e5edfc;
}
.layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-expanded) .bg-menu-theme .menu-inner > .menu-item.active .menu-link {
  color: #5a8dee;
}
.bg-menu-theme .menu-sub > .menu-item > .menu-link:before {
  background-color: #a8b1bb !important;
}
.bg-menu-theme .menu-sub > .menu-item.active > .menu-link:not(.menu-toggle):before {
  background-color: #5a8dee !important;
}
.bg-menu-theme .menu-inner > .menu-header::before {
  background-color: #7d8a99;
}
.bg-menu-theme.menu-horizontal {
  background-color: #fff !important;
  box-shadow: none;
  border-bottom: 1px solid #d4d8dd;
}
.bg-menu-theme.menu-horizontal .menu-inner > .menu-item.active > .menu-link {
  background-color: #e5edfc !important;
}
.bg-menu-theme.menu-horizontal .menu-inner > .menu-item .menu-sub {
  box-shadow: none;
  border: 1px solid #d4d8dd;
}
html:not(.layout-menu-collapsed) .bg-menu-theme.menu-horizontal .menu-inner .menu-item.open:not(.active) > .menu-link, .layout-menu-hover.layout-menu-collapsed .bg-menu-theme.menu-horizontal .menu-inner .menu-item.open:not(.active) > .menu-link,
html:not(.layout-menu-collapsed) .bg-menu-theme.menu-horizontal .menu-inner .menu-item:not(.active) .menu-link:hover,
.layout-menu-hover.layout-menu-collapsed .bg-menu-theme.menu-horizontal .menu-inner .menu-item:not(.active) .menu-link:hover,
html:not(.layout-menu-collapsed) .bg-menu-theme.menu-horizontal .menu-inner .menu-sub .menu-item.active .menu-link:not(.menu-toggle):hover,
.layout-menu-hover.layout-menu-collapsed .bg-menu-theme.menu-horizontal .menu-inner .menu-sub .menu-item.active .menu-link:not(.menu-toggle):hover {
  background-color: rgba(38, 60, 85, 0.06) !important;
  color: #516377;
}
html:not(.layout-menu-collapsed) .bg-menu-theme.menu-horizontal .menu-inner > .menu-item.open:not(.active) {
  background-color: transparent;
}
.bg-menu-theme.menu-horizontal .menu-inner .menu-item:not(.menu-item-closing) > .menu-sub {
  background: #fff !important;
}
.bg-menu-theme.menu-horizontal .menu-inner .menu-sub > .menu-item.active > .menu-link.menu-toggle,
.bg-menu-theme.menu-horizontal .menu-inner .menu-sub > .menu-item.open > .menu-link.menu-toggle {
  background-color: rgba(38, 60, 85, 0.06) !important;
}
.bg-menu-theme.menu-horizontal .menu-inner .menu-sub > .menu-item.open:not(.active) .menu-link.menu-toggle .menu-icon {
  color: #677788;
}
.bg-menu-theme.menu-horizontal .menu-inner > .menu-item.active > .menu-link {
  color: #5a8dee;
}

.timeline .timeline-item .timeline-event:after {
  content: "";
}

.app-brand .layout-menu-toggle i {
  color: #5a8dee;
}

.bg-footer-theme {
  background-color: #fff !important;
  color: #677788;
}
.bg-footer-theme .footer-link {
  color: #677788;
}
.bg-footer-theme .footer-link:hover, .bg-footer-theme .footer-link:focus {
  color: #516377;
}
.bg-footer-theme .footer-link.disabled {
  color: #a4adb8 !important;
}
.bg-footer-theme .footer-text {
  color: #516377;
}
.bg-footer-theme .show > .footer-link,
.bg-footer-theme .active > .footer-link,
.bg-footer-theme .footer-link.show,
.bg-footer-theme .footer-link.active {
  color: #516377;
}
.bg-footer-theme hr {
  border-color: rgba(81, 99, 119, 0.075);
}

.layout-footer-fixed .content-footer {
  box-shadow: none;
  border-top: 1px solid #d4d8dd;
}

.card {
  box-shadow: none;
  border: 1px solid #d4d8dd;
}

.accordion .accordion-item {
  border-top: 1px solid #d4d8dd;
  box-shadow: none !important;
}

.nav-align-top .nav-pills + .tab-content,
.nav-align-top .tabs-line + .tab-content {
  border: 1px solid #d4d8dd;
  box-shadow: none;
}

.bs-stepper:not(.wizard-modern) {
  box-shadow: none !important;
  border: 1px solid #d4d8dd;
  border-radius: 0.3125rem;
}

.bs-stepper.wizard-modern .bs-stepper-content {
  box-shadow: none !important;
  border: 1px solid #d4d8dd;
  border-radius: 0.3125rem;
}

.dz-preview {
  box-shadow: none !important;
  border: 1px solid #d4d8dd !important;
}
