.app-calendar-wrapper {
	position: relative;
	border-radius: 0.25rem;
}
.app-calendar-wrapper .app-calendar-sidebar {
	position: absolute;
	overflow: hidden;
	flex-grow: 0;
	flex-basis: 18.25rem;
	left: calc(-18.25rem - 1.2rem);
	height: 100%;
	width: 18.25rem;
	transition: all 0.2s;
	z-index: 4;
}
.app-calendar-wrapper .app-calendar-sidebar.show {
	left: 0;
}
.app-calendar-wrapper .app-calendar-sidebar .flatpickr-calendar {
	box-shadow: none;
}
.app-calendar-wrapper .app-calendar-sidebar .flatpickr-calendar .flatpickr-month,
.app-calendar-wrapper .app-calendar-sidebar .flatpickr-calendar .flatpickr-weekday,
.app-calendar-wrapper .app-calendar-sidebar .flatpickr-calendar .flatpickr-weekdays {
	background: rgba(0, 0, 0, 0);
}
.app-calendar-wrapper .app-calendar-sidebar .flatpickr-calendar .flatpickr-days {
	border: 0;
}
.app-calendar-wrapper .app-calendar-sidebar .flatpickr-calendar:focus {
	outline: 0;
}
.app-calendar-wrapper .app-calendar-content {
	position: relative;
}
.app-calendar-wrapper .fc-toolbar h2 {
	font-size: 1.125rem;
}
@media (max-width: 767.98px) {
	.app-calendar-wrapper .fc-toolbar h2 {
		font-size: 1rem;
	}
}
.app-calendar-wrapper table.fc-scrollgrid {
	border-left: 0;
	border-right: 0;
}
.app-calendar-wrapper table.fc-scrollgrid td {
	border-right: 0;
}
.app-calendar-wrapper .fc-dayGridMonth-view table.fc-scrollgrid td {
	border-bottom: 0;
}
.app-calendar-wrapper .fc-header-toolbar {
	margin-bottom: 1.7rem !important;
}
.app-calendar-wrapper .fc-view-container {
	margin: 0 -1.6rem;
}
.app-calendar-wrapper .event-sidebar .ql-editor {
	min-height: 5rem;
}
.app-calendar-wrapper .event-sidebar .select2 .select2-selection__choice {
	display: flex;
}
.app-calendar-wrapper .event-sidebar .select2 .select2-selection__choice .avatar {
	display: none;
}
@media (min-width: 992px) {
	.app-calendar-wrapper .app-calendar-sidebar {
		position: static;
		height: auto;
		background-color: rgba(0, 0, 0, 0) !important;
	}
	.app-calendar-wrapper .app-calendar-sidebar .flatpickr-days {
		background-color: rgba(0, 0, 0, 0);
	}
}
[dir='rtl'] .app-calendar-wrapper .fc .fc-toolbar .fc-sidebarToggle-button {
	order: 1;
}
[dir='rtl'] .app-calendar-wrapper .app-calendar-sidebar {
	left: auto;
	right: calc(-18.25rem - 1.2rem);
}
[dir='rtl'] .app-calendar-wrapper .app-calendar-sidebar.show {
	left: auto;
	right: 0;
}
.light-style .app-calendar-wrapper .app-calendar-sidebar {
	background-color: #fff;
	box-shadow: 0 0 0 1px rgba(38, 60, 85, 0.075);
}
.light-style .app-calendar-wrapper .app-calendar-sidebar .flatpickr-calendar .flatpickr-month,
.light-style .app-calendar-wrapper .app-calendar-sidebar .flatpickr-calendar .flatpickr-weekday,
.light-style .app-calendar-wrapper .app-calendar-sidebar .flatpickr-calendar .flatpickr-weekdays {
	color: #677788;
}
.light-style .app-calendar-wrapper .app-calendar-sidebar .flatpickr-calendar .flatpickr-months svg {
	fill: #677788;
}
@media (min-width: 992px) {
	.light-style .app-calendar-sidebar {
		box-shadow: none !important;
		border-right: 1px solid #d4d8dd;
	}
	.light-style[dir='rtl'] .app-calendar-sidebar {
		border-right: 0;
		border-left: 1px solid #d4d8dd;
	}
}
.dark-style .app-calendar-wrapper .app-calendar-sidebar {
	background-color: #283144;
	box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.075);
}
.dark-style .app-calendar-wrapper .app-calendar-sidebar .flatpickr-calendar {
	background-color: #283144;
}
.dark-style .app-calendar-wrapper .app-calendar-sidebar .flatpickr-calendar .flatpickr-days {
	background-color: #283144;
}
@media (min-width: 992px) {
	.dark-style .app-calendar-sidebar {
		box-shadow: none !important;
		border-right: 1px solid #36445d;
	}
	.dark-style[dir='rtl'] .app-calendar-sidebar {
		border-right: 0;
		border-left: 1px solid #36445d;
	}
}

.fc .fc-toolbar {
	flex-wrap: wrap;
}

.fc .fc-toolbar .fc-prev-button,
.fc .fc-toolbar .fc-next-button {
	display: inline-block;
	background-color: rgba(0, 0, 0, 0);
	border-color: rgba(0, 0, 0, 0);
}

.fc .fc-toolbar .fc-prev-button:hover,
.fc .fc-toolbar .fc-prev-button:active,
.fc .fc-toolbar .fc-prev-button:focus,
.fc .fc-toolbar .fc-next-button:hover,
.fc .fc-toolbar .fc-next-button:active,
.fc .fc-toolbar .fc-next-button:focus {
	background-color: rgba(0, 0, 0, 0) !important;
	border-color: rgba(0, 0, 0, 0) !important;
	box-shadow: none !important;
}

.fc .fc-toolbar .fc-prev-button {
	padding-left: 0 !important;
}

.fc .fc-toolbar .fc-button:not(.fc-next-button):not(.fc-prev-button) {
	padding: 0.438rem 1.125rem;
}

.fc .fc-toolbar .fc-button:not(.fc-next-button):not(.fc-prev-button):active,
.fc .fc-toolbar .fc-button:not(.fc-next-button):not(.fc-prev-button):focus {
	box-shadow: none !important;
}

.fc .fc-toolbar > * > :not(:first-child) {
	margin-left: 0 !important;
}

[dir='rtl'] .fc .fc-toolbar > * > :not(:first-child) {
	margin-right: 0 !important;
}

.fc .fc-toolbar .fc-toolbar-chunk {
	display: flex;
	align-items: center;
}

.fc .fc-toolbar .fc-button-group .fc-button {
	text-transform: capitalize;
}

.fc .fc-toolbar .fc-button-group + div {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.fc .fc-toolbar .fc--button:empty,
.fc .fc-toolbar .fc-toolbar-chunk:empty {
	display: none;
}

.fc .fc-toolbar .fc-sidebarToggle-button + div {
	margin-left: 0;
}

.fc .fc-view-harness {
	min-height: 650px;
}

.fc .fc-daygrid-event {
	border-radius: 0.25rem;
}

.fc .fc-h-event .fc-event-main,
.fc .fc-v-event .fc-event-main {
	color: inherit !important;
}

.fc .fc-daygrid-body-natural .fc-daygrid-day-events {
	margin-top: 0.5rem !important;
	margin-bottom: 0.5rem !important;
}

.fc .fc-view-harness {
	margin: 0 -1.5rem;
}

.fc .fc-view-harness .fc-daygrid-body .fc-daygrid-day .fc-daygrid-day-top {
	flex-direction: row;
}

.fc .fc-view-harness .fc-daygrid-body .fc-daygrid-day .fc-daygrid-day-top .fc-daygrid-day-number {
	float: left;
	margin-left: 0.5rem;
}

.fc .fc-view-harness .fc-event {
	font-size: 0.8rem;
	font-weight: 600;
	padding: 0.25rem 0.5rem;
	border-radius: 3px;
}

.fc .fc-view-harness .fc-daygrid-event-harness .fc-event.private-event {
	background-color: rgba(0, 0, 0, 0) !important;
	border-color: rgba(0, 0, 0, 0) !important;
}

.fc .fc-view-harness .fc-event .fc-daygrid-event-dot {
	display: none;
}

.fc .fc-timegrid .fc-timegrid-divider {
	display: none;
}

.fc .fc-daygrid-event-harness + .fc-daygrid-event-harness {
	margin-top: 0.3rem !important;
}

.fc .fc-popover {
	z-index: 1090 !important;
}

.fc .fc-popover .fc-popover-header {
	padding: 0.566rem;
}

.fc .fc-timegrid-event-harness .fc-timegrid-event .fc-event-time {
	font-size: 0.813rem;
}

.fc-theme-standard .fc-list {
	border: 0 !important;
}

.light-style .fc .fc-toolbar .fc-prev-button .fc-icon,
.light-style .fc .fc-toolbar .fc-next-button .fc-icon {
	color: #677788;
}

.light-style .fc .fc-day-other .fc-daygrid-day-top {
	opacity: 1;
	color: #a8b1bb;
}

.light-style .fc table.fc-scrollgrid {
	border-color: #d4d8dd;
}

.light-style .fc table.fc-scrollgrid .fc-col-header tbody {
	border: none;
}

.light-style .fc table.fc-scrollgrid .fc-col-header .fc-col-header-cell {
	border-color: #d4d8dd;
}

.light-style .fc table.fc-scrollgrid td {
	border-color: #d4d8dd;
}

.light-style .fc .private-event .fc-event-time,
.light-style .fc .private-event .fc-event-title {
	color: #ff5b5c;
}

.light-style .fc .fc-day-today {
	background-color: #f2f2f6 !important;
}

.light-style .fc .fc-popover {
	box-shadow: 0 0.625rem 1.25rem rgba(147, 158, 170, 0.5);
	border-color: #d4d8dd;
}

.light-style .fc .fc-popover .fc-popover-header {
	background: #fff;
}

.light-style .fc .fc-popover .fc-popover-header .fc-popover-close {
	color: #a8b1bb;
}

.light-style .fc .fc-list {
	border-color: #d4d8dd;
}

.light-style .fc .fc-list .fc-list-table th {
	border: 0;
	background: #f2f2f6;
}

.light-style .fc .fc-list .fc-list-table .fc-list-event {
	cursor: pointer;
}

.light-style .fc .fc-list .fc-list-table .fc-list-event:hover td {
	background-color: rgba-to-hex(rgba(38, 60, 85, 0.06), #fff);
}

.light-style .fc .fc-list .fc-list-table .fc-list-event td {
	border-color: #d4d8dd;
}

.light-style .fc .fc-list .fc-list-empty {
	background-color: #f2f2f6;
}

.light-style .fc table,
.light-style .fc tbody,
.light-style .fc thead,
.light-style .fc tbody td {
	border-color: #d4d8dd;
}

.light-style .fc-theme-standard .fc-list {
	border-top: 1px solid #d4d8dd !important;
}

.light-style .fc-theme-standard .fc-list .fc-list-day-cushion.fc-cell-shaded {
	background: #f2f2f6;
}

.light-style .fc-event-primary:not(.fc-list-event) {
	background-color: #e5edfc !important;
	color: #5a8dee !important;
}

.light-style .fc-event-primary:not(.fc-list-event) {
	border-color: rgba(0, 0, 0, 0);
}

.light-style:not([dir='rtl']) .fc-event-primary:not(.fc-list-event) {
	border-left: 3px solid #5a8dee;
}

.light-style[dir='rtl'] .fc-event-primary:not(.fc-list-event) {
	border-right: 3px solid #5a8dee;
}

.light-style .fc-event-primary.fc-list-event .fc-list-event-dot {
	border-color: #5a8dee !important;
}

.light-style .fc-event-secondary:not(.fc-list-event) {
	background-color: #e7ebef !important;
	color: #69809a !important;
}

.light-style .fc-event-secondary:not(.fc-list-event) {
	border-color: rgba(0, 0, 0, 0);
}

.light-style:not([dir='rtl']) .fc-event-secondary:not(.fc-list-event) {
	border-left: 3px solid #69809a;
}

.light-style[dir='rtl'] .fc-event-secondary:not(.fc-list-event) {
	border-right: 3px solid #69809a;
}

.light-style .fc-event-secondary.fc-list-event .fc-list-event-dot {
	border-color: #69809a !important;
}

.light-style .fc-event-success:not(.fc-list-event) {
	background-color: #dff9ec !important;
	color: #39da8a !important;
}

.light-style .fc-event-success:not(.fc-list-event) {
	border-color: rgba(0, 0, 0, 0);
}

.light-style:not([dir='rtl']) .fc-event-success:not(.fc-list-event) {
	border-left: 3px solid #39da8a;
}

.light-style[dir='rtl'] .fc-event-success:not(.fc-list-event) {
	border-right: 3px solid #39da8a;
}

.light-style .fc-event-success.fc-list-event .fc-list-event-dot {
	border-color: #39da8a !important;
}

.light-style .fc-event-info:not(.fc-list-event) {
	background-color: #d6f7fa !important;
	color: #00cfdd !important;
}

.light-style .fc-event-info:not(.fc-list-event) {
	border-color: rgba(0, 0, 0, 0);
}

.light-style:not([dir='rtl']) .fc-event-info:not(.fc-list-event) {
	border-left: 3px solid #00cfdd;
}

.light-style[dir='rtl'] .fc-event-info:not(.fc-list-event) {
	border-right: 3px solid #00cfdd;
}

.light-style .fc-event-info.fc-list-event .fc-list-event-dot {
	border-color: #00cfdd !important;
}

.light-style .fc-event-warning:not(.fc-list-event) {
	background-color: #fff2e1 !important;
	color: #fdac41 !important;
}

.light-style .fc-event-warning:not(.fc-list-event) {
	border-color: rgba(0, 0, 0, 0);
}

.light-style:not([dir='rtl']) .fc-event-warning:not(.fc-list-event) {
	border-left: 3px solid #fdac41;
}

.light-style[dir='rtl'] .fc-event-warning:not(.fc-list-event) {
	border-right: 3px solid #fdac41;
}

.light-style .fc-event-warning.fc-list-event .fc-list-event-dot {
	border-color: #fdac41 !important;
}

.light-style .fc-event-danger:not(.fc-list-event) {
	background-color: #ffe5e5 !important;
	color: #ff5b5c !important;
}

.light-style .fc-event-danger:not(.fc-list-event) {
	border-color: rgba(0, 0, 0, 0);
}

.light-style:not([dir='rtl']) .fc-event-danger:not(.fc-list-event) {
	border-left: 3px solid #ff5b5c;
}

.light-style[dir='rtl'] .fc-event-danger:not(.fc-list-event) {
	border-right: 3px solid #ff5b5c;
}

.light-style .fc-event-danger.fc-list-event .fc-list-event-dot {
	border-color: #ff5b5c !important;
}

.light-style .fc-event-light:not(.fc-list-event) {
	background-color: #fbfbfc !important;
	color: #e5e9ed !important;
}

.light-style .fc-event-light:not(.fc-list-event) {
	border-color: rgba(0, 0, 0, 0);
}

.light-style:not([dir='rtl']) .fc-event-light:not(.fc-list-event) {
	border-left: 3px solid #e5e9ed;
}

.light-style[dir='rtl'] .fc-event-light:not(.fc-list-event) {
	border-right: 3px solid #e5e9ed;
}

.light-style .fc-event-light.fc-list-event .fc-list-event-dot {
	border-color: #e5e9ed !important;
}

.light-style .fc-event-dark:not(.fc-list-event) {
	background-color: #e2e4e6 !important;
	color: #495563 !important;
}

.light-style .fc-event-dark:not(.fc-list-event) {
	border-color: rgba(0, 0, 0, 0);
}

.light-style:not([dir='rtl']) .fc-event-dark:not(.fc-list-event) {
	border-left: 3px solid #495563;
}

.light-style[dir='rtl'] .fc-event-dark:not(.fc-list-event) {
	border-right: 3px solid #495563;
}

.light-style .fc-event-dark.fc-list-event .fc-list-event-dot {
	border-color: #495563 !important;
}

.light-style .fc-event-gray:not(.fc-list-event) {
	background-color: rgba(242, 243, 245, 0.92) !important;
	color: rgba(38, 60, 85, 0.5) !important;
}

.light-style .fc-event-gray:not(.fc-list-event) {
	border-color: rgba(0, 0, 0, 0);
}

.light-style:not([dir='rtl']) .fc-event-gray:not(.fc-list-event) {
	border-left: 3px solid rgba(38, 60, 85, 0.5);
}

.light-style[dir='rtl'] .fc-event-gray:not(.fc-list-event) {
	border-right: 3px solid rgba(38, 60, 85, 0.5);
}

.light-style .fc-event-gray.fc-list-event .fc-list-event-dot {
	border-color: rgba(38, 60, 85, 0.5) !important;
}

.dark-style .fc .fc-toolbar .fc-prev-button .fc-icon,
.dark-style .fc .fc-toolbar .fc-next-button .fc-icon {
	color: #a1b0cb;
}

.dark-style .fc .fc-toolbar .fc-sidebarToggle-button {
	color: #fff;
}

.dark-style .fc .fc-day-other .fc-daygrid-day-top {
	opacity: 1;
	color: #8295ba;
}

.dark-style .fc table.fc-scrollgrid {
	border-color: #36445d;
}

.dark-style .fc table.fc-scrollgrid .fc-col-header tbody {
	border: none;
}

.dark-style .fc table.fc-scrollgrid .fc-col-header .fc-col-header-cell {
	border-color: #36445d;
}

.dark-style .fc table.fc-scrollgrid td {
	border-color: #36445d;
}

.dark-style .fc .private-event .fc-event-time,
.dark-style .fc .private-event .fc-event-title {
	color: #ff5b5c;
}

.dark-style .fc .fc-day-today {
	background-color: #1c222f !important;
}

.dark-style .fc .fc-day-today .fc-popover-body {
	background-color: #283144 !important;
}

.dark-style .fc .fc-divider {
	background: #36445d;
	border-color: #36445d;
}

.dark-style .fc .fc-popover {
	background-color: #283144;
	box-shadow: 0 0.625rem 1.25rem rgba(0, 0, 0, 0.3);
	border: 0;
}

.dark-style .fc .fc-popover .fc-popover-header {
	background-color: #283144;
}

.dark-style .fc .fc-list {
	border-color: #36445d;
}

.dark-style .fc .fc-list .fc-list-table th {
	border: 0;
	background: #1c222f;
}

.dark-style .fc .fc-list .fc-list-table .fc-list-event {
	cursor: pointer;
}

.dark-style .fc .fc-list .fc-list-table .fc-list-event:hover td {
	background-color: rgba(99, 123, 169, 0.06);
}

.dark-style .fc .fc-list .fc-list-table .fc-list-event td {
	border-color: #36445d;
}

.dark-style .fc .fc-list .fc-list-empty {
	background-color: #1c222f;
}

.dark-style .fc table,
.dark-style .fc .fc-timegrid-axis,
.dark-style .fc tbody,
.dark-style .fc thead,
.dark-style .fc tbody td {
	border-color: #36445d;
}

.dark-style .fc-theme-standard .fc-list {
	border-top: 1px solid #36445d !important;
}

.dark-style .fc-theme-standard .fc-list .fc-list-day-cushion.fc-cell-shaded {
	background: #1c222f;
}

.dark-style .fc-event-primary:not(.fc-list-event) {
	background-color: #30405f !important;
	color: #5a8dee !important;
}

.dark-style .fc-event-primary:not(.fc-list-event) {
	border-color: rgba(0, 0, 0, 0);
	box-shadow: none;
}

.dark-style:not([dir='rtl']) .fc-event-primary:not(.fc-list-event) {
	border-left: 3px solid #5a8dee;
}

.dark-style[dir='rtl'] .fc-event-primary:not(.fc-list-event) {
	border-right: 3px solid #5a8dee;
}

.dark-style .fc-event-primary.fc-list-event .fc-list-event-dot {
	border-color: #5a8dee !important;
}

.dark-style .fc-event-secondary:not(.fc-list-event) {
	background-color: #323e52 !important;
	color: #69809a !important;
}

.dark-style .fc-event-secondary:not(.fc-list-event) {
	border-color: rgba(0, 0, 0, 0);
	box-shadow: none;
}

.dark-style:not([dir='rtl']) .fc-event-secondary:not(.fc-list-event) {
	border-left: 3px solid #69809a;
}

.dark-style[dir='rtl'] .fc-event-secondary:not(.fc-list-event) {
	border-right: 3px solid #69809a;
}

.dark-style .fc-event-secondary.fc-list-event .fc-list-event-dot {
	border-color: #69809a !important;
}

.dark-style .fc-event-success:not(.fc-list-event) {
	background-color: #2b4c4f !important;
	color: #39da8a !important;
}

.dark-style .fc-event-success:not(.fc-list-event) {
	border-color: rgba(0, 0, 0, 0);
	box-shadow: none;
}

.dark-style:not([dir='rtl']) .fc-event-success:not(.fc-list-event) {
	border-left: 3px solid #39da8a;
}

.dark-style[dir='rtl'] .fc-event-success:not(.fc-list-event) {
	border-right: 3px solid #39da8a;
}

.dark-style .fc-event-success.fc-list-event .fc-list-event-dot {
	border-color: #39da8a !important;
}

.dark-style .fc-event-info:not(.fc-list-event) {
	background-color: #224a5c !important;
	color: #00cfdd !important;
}

.dark-style .fc-event-info:not(.fc-list-event) {
	border-color: rgba(0, 0, 0, 0);
	box-shadow: none;
}

.dark-style:not([dir='rtl']) .fc-event-info:not(.fc-list-event) {
	border-left: 3px solid #00cfdd;
}

.dark-style[dir='rtl'] .fc-event-info:not(.fc-list-event) {
	border-right: 3px solid #00cfdd;
}

.dark-style .fc-event-info.fc-list-event .fc-list-event-dot {
	border-color: #00cfdd !important;
}

.dark-style .fc-event-warning:not(.fc-list-event) {
	background-color: #4a4544 !important;
	color: #fdac41 !important;
}

.dark-style .fc-event-warning:not(.fc-list-event) {
	border-color: rgba(0, 0, 0, 0);
	box-shadow: none;
}

.dark-style:not([dir='rtl']) .fc-event-warning:not(.fc-list-event) {
	border-left: 3px solid #fdac41;
}

.dark-style[dir='rtl'] .fc-event-warning:not(.fc-list-event) {
	border-right: 3px solid #fdac41;
}

.dark-style .fc-event-warning.fc-list-event .fc-list-event-dot {
	border-color: #fdac41 !important;
}

.dark-style .fc-event-danger:not(.fc-list-event) {
	background-color: #4a3848 !important;
	color: #ff5b5c !important;
}

.dark-style .fc-event-danger:not(.fc-list-event) {
	border-color: rgba(0, 0, 0, 0);
	box-shadow: none;
}

.dark-style:not([dir='rtl']) .fc-event-danger:not(.fc-list-event) {
	border-left: 3px solid #ff5b5c;
}

.dark-style[dir='rtl'] .fc-event-danger:not(.fc-list-event) {
	border-right: 3px solid #ff5b5c;
}

.dark-style .fc-event-danger.fc-list-event .fc-list-event-dot {
	border-color: #ff5b5c !important;
}

.dark-style .fc-event-light:not(.fc-list-event) {
	background-color: #2c3548 !important;
	color: #434c5f !important;
}

.dark-style .fc-event-light:not(.fc-list-event) {
	border-color: rgba(0, 0, 0, 0);
	box-shadow: none;
}

.dark-style:not([dir='rtl']) .fc-event-light:not(.fc-list-event) {
	border-left: 3px solid #434c5f;
}

.dark-style[dir='rtl'] .fc-event-light:not(.fc-list-event) {
	border-right: 3px solid #434c5f;
}

.dark-style .fc-event-light.fc-list-event .fc-list-event-dot {
	border-color: #434c5f !important;
}

.dark-style .fc-event-dark:not(.fc-list-event) {
	background-color: #343c4e !important;
	color: #717784 !important;
}

.dark-style .fc-event-dark:not(.fc-list-event) {
	border-color: rgba(0, 0, 0, 0);
	box-shadow: none;
}

.dark-style:not([dir='rtl']) .fc-event-dark:not(.fc-list-event) {
	border-left: 3px solid #717784;
}

.dark-style[dir='rtl'] .fc-event-dark:not(.fc-list-event) {
	border-right: 3px solid #717784;
}

.dark-style .fc-event-dark.fc-list-event .fc-list-event-dot {
	border-color: #717784 !important;
}

.dark-style .fc-event-gray:not(.fc-list-event) {
	background-color: rgba(62, 70, 87, 0.96) !important;
	color: rgba(255, 255, 255, 0.75) !important;
}

.dark-style .fc-event-gray:not(.fc-list-event) {
	border-color: rgba(0, 0, 0, 0);
	box-shadow: none;
}

.dark-style:not([dir='rtl']) .fc-event-gray:not(.fc-list-event) {
	border-left: 3px solid rgba(255, 255, 255, 0.75);
}

.dark-style[dir='rtl'] .fc-event-gray:not(.fc-list-event) {
	border-right: 3px solid rgba(255, 255, 255, 0.75);
}

.dark-style .fc-event-gray.fc-list-event .fc-list-event-dot {
	border-color: rgba(255, 255, 255, 0.75) !important;
}

@media (max-width: 575.98px) {
	.fc .fc-header-toolbar .fc-toolbar-chunk + .fc-toolbar-chunk {
		margin-top: 1rem;
	}
}
