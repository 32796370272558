.kanban-container {
	display: flex;
	flex-wrap: nowrap;
	overflow-x: auto;
}

.kanban-column {
	width: 21rem;
	margin-right: 0.5rem;
}

.kanban-column .card-title {
	white-space: nowrap;
}

@media (max-width: 768px) {
	.kanban-column {
		width: 25rem;
	}
}

.kanban-item {
	background-color: #fff;
	border: 1px solid #e0e0e0;
	border-radius: 0.25rem;
	padding: 0rem 0.75rem;
	margin-bottom: 1rem;
}
